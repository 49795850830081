import {Button, Cascader, Form, Input, Modal, Select} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import AddNewPictures from '../AddNewPictures';
// @ts-ignore
import {debounce} from "lodash"
import {showFail, showNeedCompleteInfo, showNetError, showSuccess} from "../functions";
import {addCompany} from "../../api";


const useResetFormOnCloseModal = ({form, open}: { form: any, open: boolean }) => {
    const prevOpenRef: any = useRef();
    useEffect(() => {
        prevOpenRef.current = open;
    }, [open]);
    const prevOpen = prevOpenRef.current;
    useEffect(() => {
        if (!open && prevOpen) {
            form.resetFields();
        }
    }, [form, prevOpen, open]);
};

const AddCompanyModal = ({
                             open,
                             onClose,
                             handleGetCompany,
                             streets
                         }: { open: boolean, onClose: () => void,  handleGetCompany: any ,streets:any[]}) => {
    const [form] = Form.useForm();
    useResetFormOnCloseModal({
        form,
        open,
    });

    const [generatedCompanyId, setGeneratedCompanyId] = useState<string>("");
    const handleGetGeneratedCommunityId = () => {

    }

    useEffect(() => {
        if (open) {
            handleGetGeneratedCommunityId()
        }
    }, [open])

    const [loc, setLoc] = useState<any>()
    const [initLoc, setInitLoc] = useState({lng: 120.09, lat: 30.88});


    //新增宿舍中的选择位置对话框
    const [pickLocModalOpen, setPickLocModalOpen] = useState(false);
    const handleCancelLocModal = () => {
        setPickLocModalOpen(false);
    }


    const confirmLoc = (e: any) => {
        setLoc(e);
        form.setFieldsValue({"location": e})
    }

    const confirmCompanyName = (e: any) => {
        form.setFieldsValue({"companyName": e})
    }

    const confirmRoad = (e: any) => {
        form.setFieldsValue({"road": e})
    }

    const confirmAddress = (e: any) => {
        form.setFieldsValue({"companyAddress": e})
    }


    const getLocation = () => {
        console.log(navigator.geolocation)
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((l) => {
                if (l?.coords) {
                    setInitLoc({lng: l?.coords?.longitude, lat: l?.coords?.latitude})
                } else {
                    showFail('获取定位失败')
                }
            });
        } else {
            alert("浏览器不支持地理定位。");
        }
    }

    const [user, setUser] = useState<any>()
    const [pickUserModalOpen, setPickUserModalOpen] = useState(false);
    const handleCancelUserModal = () => {
        setPickUserModalOpen(false);
    }

    const confirmUser = (e: any) => {
        setUser(e);
        form.setFieldsValue({"userId": e?.userId})
    }


    const [loading, setLoading] = useState<boolean>(false);
    const handleAddNewCompany = () => {
        form.validateFields().then((value) => {
            let company = {
                ...value,
                companyId:generatedCompanyId
            }
            setLoading(true);
            addCompany(company).then((res: any) => {
                setLoading(false);
                console.log(res)
                if (res?.code === 200) {
                    handleGetCompany().then(() => {
                        showSuccess(res?.msg);
                        onClose();
                        setLoc(undefined);
                        setUser(undefined);
                    })
                } else {
                    showFail(res?.msg)
                }
            }).catch((err: any) => {
                console.log(err)
            })

        }).catch((err) => {
            console.log(err);
            showNeedCompleteInfo();
        })
    }


    return (
        <div>
            <Modal open={open} onCancel={() => {
                onClose();
                setLoc(undefined);
                setLoading(false)
            }} title={'单位新增'} maskClosable={false} okText={'确认提交'}
                   onOk={debounce(handleAddNewCompany, 1000)} okButtonProps={{loading: loading}} destroyOnClose>
                <Form form={form} name="form" labelAlign="right" labelCol={{
                    xs: {span: 24},
                    sm: {span: 6},
                }} wrapperCol={{
                    xs: {span: 24},
                    sm: {span: 14},
                }}>
                            <Form.Item
                                name="companyName"
                                label="单位名称"
                                rules={[
                                    {
                                        required: true, message: "请输入单位名称"
                                    },
                                ]}
                            >
                                <Input placeholder="请输入单位名称"/>
                            </Form.Item>
                            <Form.Item
                                name="address"
                                label="具体地址"
                            >
                                <Input.TextArea placeholder="请输入单位具体地址"/>
                            </Form.Item>
                    <Form.Item
                        name="streetId"
                        label="所属街道"
                    >
                       <Select options={streets}/>
                    </Form.Item>

                    {/*<Form.Item*/}
                    {/*    name="contactName"*/}
                    {/*    label="联系人姓名"*/}
                    {/*>*/}
                    {/*    <Input placeholder="请输入单位联系人姓名"/>*/}
                    {/*</Form.Item>*/}
                    {/*<Form.Item*/}
                    {/*    name="contactMobile"*/}
                    {/*    label="联系人电话"*/}
                    {/*>*/}
                    {/*    <Input placeholder="请输入单位联系人电话"/>*/}
                    {/*</Form.Item>*/}
                    {/*<Form.Item*/}
                    {/*    name="remark"*/}
                    {/*    label="备注"*/}
                    {/*>*/}
                    {/*    <Input placeholder="请输入单位备注"/>*/}
                    {/*</Form.Item>*/}

                </Form>



            </Modal>

        </div>
    );
};

export default AddCompanyModal;