import {Button,  Modal} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import styles from "./index.module.css";


const AssignAppointmentMemberDetailModal = ({open, onCancel, record}: any) => {

    return (
        <Modal
            width={'40vw'}
            maskClosable={false}
            title='任务人员分配情况'
            open={open}
            onCancel={onCancel}
            footer={[
                <Button key="back" type={'primary'}  onClick={() => onCancel()}>
                    知道了
                </Button>,
            ]}
        >
            <div>
                {
                    record?.supervisionUser&&(
                        <div className={styles.infoItem}>
                            <div className={styles.infoTitle}>
                                监督员
                            </div>
                            <div className={styles.infoContent}>
                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <div
                                        style={{width: '200px'}}>{record?.supervisionUser?.username} {record?.supervisionUser?.mobile}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }

                {record?.publicizeUser&&(
                    <div className={styles.infoItem}>
                        <div className={styles.infoTitle}>
                            宣传员
                        </div>
                        <div className={styles.infoContent}>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <div
                                    style={{width: '200px'}}>{record?.publicizeUser?.username} {record?.publicizeUser?.mobile}
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {record?.safeUser&&(
                    <div className={styles.infoItem}>
                        <div className={styles.infoTitle}>
                            宣传员
                        </div>
                        <div className={styles.infoContent}>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <div
                                    style={{width: '200px'}}>{record?.safeUser?.username} {record?.safeUser?.mobile}
                                </div>
                            </div>
                        </div>
                    </div>
                )}

            </div>

        </Modal>
    );
};

export default AssignAppointmentMemberDetailModal;