import {Button, Cascader, Checkbox, DatePicker, Form, Modal, Select} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import dayjs from 'dayjs';
import PickAppointmentUser from "../pickAppointmentUser";
import {assignTaskMember, updateCompany} from "../../api";
import {formatDate, showFail, showNeedCompleteInfo, showSuccess} from "../functions";

const useResetFormOnCloseModal = ({form, open}: { form: any, open: boolean }) => {
    const prevOpenRef: any = useRef();
    useEffect(() => {
        prevOpenRef.current = open;
    }, [open]);
    const prevOpen = prevOpenRef.current;
    useEffect(() => {
        if (!open && prevOpen) {
            form.resetFields();
        }
    }, [form, prevOpen, open]);
};

const AssignAppointmentMemberModal = ({open, onCancel, appointmentId,handleGetAppointment}: any) => {
    const dateFormat = 'YYYY-MM-DD';
    const maxDate = "2025-01-06";
    const minDate = "2025-01-01";
    const [form] = Form.useForm();
    useResetFormOnCloseModal({
        form,
        open,
    });

    const [pickAppointmentUserModalOpen, setPickAppointmentUserModalOpen] = useState<boolean>(false);
    const handlePickAppointmentUserModalClose = () => {
        setPickAppointmentUserModalOpen(false);
    }

    const [userType, setUserType] = useState<number>(1);
    const [supervisor, setSupervisor] = useState<any>();
    const [promoter, setPromoter] = useState<any>();
    const [inspector, setInspector] = useState<any>();
    const [allPickUser, setAllPickUser] = useState<any>([]);

    const [confirmDate, setConfirmDate] = useState<any>();
    const handleConfirmDateChange = (e: any) => {
        setConfirmDate(e);
        setConfirmDateExtend("");
        setRolesPartIn([]);
        setAllPickUser([]);
        setSupervisor(null);
        setPromoter(null);
        setInspector(null);
        form.resetFields(["confirmDateExtend", "rolesPartIn", "supervisor", "promoter", "inspector"])

    }

    const [confirmDateExtend, setConfirmDateExtend] = useState<any>();
    const handleConfirmDateExtendChange = (e: any) => {
        setConfirmDateExtend(e);
        setRolesPartIn([]);
        setAllPickUser([]);
        setSupervisor(null);
        setPromoter(null);
        setInspector(null);
        form.resetFields(["rolesPartIn", "supervisor", "promoter", "inspector"])
    }

    const roleOptions: any[] = [
        {
            value: "supervisor",
            label: "监督员"
        },
        {
            value: "promoter",
            label: "宣传员"
        },
        {
            value: "inspector",
            label: "防消联勤人员"
        },
    ]

    const [rolesPartIn, setRolesPartIn] = useState<any>([]);
    const handleRolesChange = (e: any) => {
        // console.log('handleRolesChange',e);
        setRolesPartIn(e);
    }

    const handleConfirmUser = (e: any) => {
        let _allPickUser = allPickUser;
        let newUser = e;
        const index = allPickUser.findIndex((user: any) => user.taskUserType === newUser.taskUserType);
        if (index !== -1) {
            // 如果找到匹配的对象，替换
            if (e) {
                _allPickUser[index] = newUser;
            } else {
                _allPickUser.splice(index, 1);
            }
        } else {
            // 如果没有找到匹配的对象，插入新对象
            if (e) {
                _allPickUser.push(newUser);
            }
        }
        setAllPickUser(_allPickUser);
        switch (userType) {
            case 1:
                setSupervisor(newUser);
                form.setFieldsValue({supervisor: newUser});
                break;
            case 2:
                setPromoter(newUser);
                form.setFieldsValue({promoter: newUser});
                break;
            case 3:
                setInspector(newUser);
                form.setFieldsValue({inspector: newUser});
                break;
            default:
                break;
        }
    }


    // const disabledDate = (current: dayjs.Dayjs) => {
    //     return current.isBefore(dayjs(minDate, dateFormat)) || current.isAfter(dayjs(maxDate, dateFormat));
    // };

    const [loading, setLoading] = useState<boolean>(false);
    const handleAssignSubmit = () => {
        form.validateFields().then((value) => {
            let data = {
                appointmentId: appointmentId,
                supervisionId: value.supervisor ? value.supervisor.userId : null,
                publicizeId: value.promoter ? value.promoter.userId : null,
                safeId: value.inspector ? value.inspector.userId : null,
                confirmDate: formatDate(value?.confirmDate?.$d) ,
                confirmDateExtend: value.confirmDateExtend,
            }
            setLoading(true);
            assignTaskMember(data).then((res: any) => {
                setLoading(false);
                handleGetAppointment().then(()=>{
                    handleClose();
                })
                if (res?.code === 200) {
                        showSuccess("分配成功!");
                } else {
                    showFail(res?.msg)
                }
            }).catch((err: any) => {
                console.log(err)
            })

        }).catch((err) => {
            console.log(err);
            showNeedCompleteInfo();
        })
    }

    const handleClose = () => {
        onCancel();
        setConfirmDate(null);
        setConfirmDateExtend("");
        setRolesPartIn([]);
        setAllPickUser([]);
        setSupervisor(null);
        setPromoter(null);
        setInspector(null);
        form.resetFields();
    }

    return (
        <Modal
            width={'60vw'}
            maskClosable={false}
            title='任务人员分配'
            open={open}
            onCancel={handleClose}
            footer={[
                <Button key="back" onClick={() => {
                    handleClose();
                }}>
                    取消
                </Button>,
                <Button loading={loading} key="submit" type={'primary'} onClick={() => {
                    handleAssignSubmit()
                }}>
                    提交
                </Button>,
            ]}
        >
            <Form form={form} name="form" labelAlign="right" labelCol={{
                xs: {span: 24},
                sm: {span: 6},
            }} wrapperCol={{
                xs: {span: 24},
                sm: {span: 14},
            }}>
                <Form.Item
                    name="confirmDate"
                    label="任务日期"
                    rules={[
                        {
                            required: true, message: "请选择培训日期"
                        },
                    ]}
                >
                    <DatePicker
                        // defaultValue={dayjs(minDate, dateFormat)}
                        // disabledDate={disabledDate}
                        onChange={handleConfirmDateChange}
                    />
                </Form.Item>

                {confirmDate && (
                    <Form.Item
                        name="confirmDateExtend"
                        label="时间段"
                        rules={[
                            {
                                required: true, message: "请选择时间段"
                            },
                        ]}
                    >
                        <Select
                            defaultValue={confirmDateExtend}
                            onChange={handleConfirmDateExtendChange}
                            placeholder="请选择时间段"
                            options={[
                                {value: '上午', label: '上午'},
                                {value: '下午', label: '下午'},
                            ]}
                        />
                    </Form.Item>
                )}

                {confirmDate && confirmDateExtend && (
                    <Form.Item
                        name="rolesPartIn"
                        label="参与角色"
                        rules={[
                            {
                                required: true, message: "请选择参与角色!"
                            },
                        ]}
                    >
                        <Checkbox.Group defaultValue={rolesPartIn} options={roleOptions} onChange={handleRolesChange}/>
                    </Form.Item>
                )}

                {rolesPartIn.includes('supervisor') && (
                    <Form.Item
                        name="supervisor"
                        label="监督员"
                        rules={[
                            {
                                required: true, message: "请选择监督员"
                            },
                        ]}
                    >
                        {supervisor ? (<div style={{display: "flex", flexDirection: "row", alignItems: 'center'}}>
                                <div style={{color: "#235c7a"}}>
                                    <div> {supervisor?.username}{supervisor?.mobile}</div>
                                </div>
                                <div style={{width: "20px"}}/>
                                <div style={{display: "grid", placeItems: "center"}}>
                                    <Button type="primary" onClick={() => {
                                        setUserType(1);
                                        setPickAppointmentUserModalOpen(true);
                                    }}>重新选择
                                    </Button>
                                </div>

                            </div>
                        ) : (
                            <Button type="primary" onClick={() => {
                                setUserType(1);
                                setPickAppointmentUserModalOpen(true);
                            }}>选择
                            </Button>
                        )}
                    </Form.Item>
                )}

                {rolesPartIn.includes("promoter") && (
                    <Form.Item
                        name="promoter"
                        label="宣传员"
                        rules={[
                            {
                                required: true, message: "请选择宣传员"
                            },
                        ]}
                    >
                        {promoter ? (<div style={{display: "flex", flexDirection: "row", alignItems: 'center'}}>
                                <div style={{color: "#235c7a"}}>
                                    <div> {promoter?.username}{promoter?.mobile}</div>
                                </div>
                                <div style={{width: "20px"}}/>
                                <div style={{display: "grid", placeItems: "center"}}>
                                    <Button type="primary" onClick={() => {
                                        setUserType(2);
                                        setPickAppointmentUserModalOpen(true);
                                    }}>重新选择
                                    </Button>
                                </div>

                            </div>
                        ) : (
                            <Button type="primary" onClick={() => {
                                setUserType(2);
                                setPickAppointmentUserModalOpen(true);
                            }}>选择
                            </Button>
                        )}
                    </Form.Item>
                )}

                {rolesPartIn.includes("inspector") && (
                    <Form.Item
                        name="inspector"
                        label="防消联勤人员"
                        rules={[
                            {
                                required: true, message: "请选择防消联勤人员"
                            },
                        ]}
                    >
                        {inspector ? (<div style={{display: "flex", flexDirection: "row", alignItems: 'center'}}>
                                <div style={{color: "#235c7a"}}>
                                    <div> {inspector?.username}{inspector?.mobile}</div>
                                </div>
                                <div style={{width: "20px"}}/>
                                <div style={{display: "grid", placeItems: "center"}}>
                                    <Button type="primary" onClick={() => {
                                        setUserType(3);
                                        setPickAppointmentUserModalOpen(true);
                                    }}>重新选择
                                    </Button>
                                </div>

                            </div>
                        ) : (
                            <Button type="primary" onClick={() => {
                                setUserType(3);
                                setPickAppointmentUserModalOpen(true);
                            }}>选择
                            </Button>
                        )}
                    </Form.Item>
                )}

            </Form>
            <PickAppointmentUser open={pickAppointmentUserModalOpen}
                                 onCancel={handlePickAppointmentUserModalClose}
                                 confirmUser={handleConfirmUser}
                                 initUser={allPickUser} type={userType}
                                 confirmDate={confirmDate} confirmDateExtend={confirmDateExtend}
                                 appointmentId={appointmentId}/>
        </Modal>
    );
};

export default AssignAppointmentMemberModal;