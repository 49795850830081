import {Button, Descriptions, Modal, Spin} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import styles from "./index.module.css";
import {getSuggestById} from "../../api";
import {showFail} from "../functions";


const SuggestDetailModal = ({open, onCancel, suggestId}: any) => {

    const [suggest,setSuggest]=useState<any>();
    const [loading,setLoading]=useState<any>();

    const handleSuggestDetail=()=>{
        setLoading(true);
        getSuggestById({id:suggestId}).then((res: any) => {
            setLoading(false);
            if (res?.code === 200) {
                setSuggest(res?.data?.[0]);
            } else {
                showFail(res?.msg)
            }
        }).catch((err) => {
            console.log(err)
        })
    };

    useEffect(()=>{
        if(open){
            handleSuggestDetail();
        }
    },[open])

    const items:any=[
        {
            key: '1',
            label: '发起时间',
            children: suggest?.createTime,
        },
        {
            key: '2',
            label: '发起人',
            children: `${suggest?.creator?.username} ${suggest?.creator?.mobile}`,
            span:'filled'
        },
        {
            key: '3',
            label: '详情',
            children: suggest?.content,
        },
    ];

    return (
        <Modal
            width={'40vw'}
            maskClosable={false}
            title='投诉与建议详情'
            open={open}
            onCancel={onCancel}
            footer={[
                <Button type={'primary'} key="back" onClick={() => onCancel()}>
                    知道了
                </Button>
            ]}
        >
            <Spin spinning={loading}>
                <Descriptions bordered items={items} />
            </Spin>
        </Modal>
    );
};

export default SuggestDetailModal;