import React, {useEffect, useState} from 'react';
import {getSuggestPage} from "../../../api";
import {showFail} from "../../../components/functions";
import {Button, Table, Tag} from "antd";
import SuggestDetailModal from "../../../components/suggestDetailModal";

const SuggestComplaint = () => {

    const [pageSize, setPageSize] = useState<number>(10);
    const [pageNum, setPageNum] = useState<number>(1);
    const [total, setTotal] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)
    const [suggestList, setSuggestList] = useState<any[]>([]);
    const onPageChange = (_page: number) => {
        setPageNum(_page);
    };

    const onPageSizeChange = (_page: number, _pageSize: number) => {
        setPageSize(_pageSize);
    };

    let params: any = {}
    const handleGetSuggestList = async (pageNum: number, pageSize: number, searchCondition?: any) => {
        setLoading(true);
        params.pageSize = pageSize;
        params.pageNum = pageNum;
        if (searchCondition?.isRead) {
            params.isRead = searchCondition?.isRead
        }
        getSuggestPage(params).then((res: any) => {
            setLoading(false);
            if (res?.code === 200) {
                setTotal(Number(res?.data?.total));
                setSuggestList(res?.data?.records);
            } else {
                showFail(res?.msg)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        handleGetSuggestList(pageNum, pageSize, newSearchCondition);
    }, [pageSize, pageNum])


    const [searchCondition, setSearchCondition] = useState({
        isRead:""
    });
    const [newSearchCondition, setNewSearchCondition] = useState(searchCondition);

    const [suggestId,setSuggestId]=useState<string>("");
    const [detailDrawerOpen, setDetailDrawerOpen] = useState<boolean>(false);
    const handleCloseDetailDrawer = () => {
        setDetailDrawerOpen(false);
        handleGetSuggestList(pageNum,pageSize,newSearchCondition)
    }

    const columns = [
        {
            title: '发起时间',
            dataIndex: 'createTime',
            key: 'createTime',
        },
        {
            title: '状态',
            dataIndex: 'isRead',
            key: 'isRead',
            render:(isRead:any)=>(
                <Tag
                    color={isRead === 1? 'success' :
                        'processing'}>{isRead===1?"已读":"未读"}</Tag>
            )
        },
        {
            title: '发起人',
            dataIndex: 'creator',
            key: 'creator',
            render: (creator: any, record: any) => (<>
                {creator?.username}  {creator?.mobile}

            </>)
        },
        {
            title: '详情',
            dataIndex: 'appointmentId',
            key: 'appointmentId',
            render: ((appointmentId: any, record: any) => (
                <>
                    <Button type={'primary'} onClick={() => {
                        setSuggestId(record?.id);
                        setDetailDrawerOpen(true);
                    }}>查看详情</Button>
                </>
            ))
        },
    ]

    return (
        <div>



            <Table rowKey={'id'} columns={columns} dataSource={suggestList}
                   loading={loading}
                   pagination={{
                       current: pageNum,
                       onChange: onPageChange,
                       onShowSizeChange: onPageSizeChange,
                       total: total,
                       showSizeChanger: true,
                       defaultPageSize: pageSize,
                       pageSizeOptions: ['10', '20', '50', '100'],
                   }}/>
            <SuggestDetailModal suggestId={suggestId} open={detailDrawerOpen}
                                     onCancel={handleCloseDetailDrawer}/>
        </div>
    );
};

export default SuggestComplaint;