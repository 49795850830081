import React from 'react';
import {Navigate, useRoutes} from 'react-router-dom';
import Layout from "./pages/Layout";
import HomePage from "./pages/homepage";
import Personal from "./pages/personal";
import Login from './pages/login';
import Hydrant from "./pages/manage/hydrant";
import Dept from "./pages/manage/dept";
import Menu from "./pages/manage/menu";
import Role from "./pages/manage/role";
import User from "./pages/manage/user";
import Area from "./pages/manage/area";
import InspectTask from "./pages/manage/inspectTask";
import InspectTaskRegular from "./pages/manage/inspectTaskRegular";
import HydrantMap from "./pages/map";
import Road from "./pages/manage/road";
import Company from "./pages/manage/company";
import Community from "./pages/manage/community";
import Exercise from "./pages/manage/exercise";
import HiddenDanger from "./pages/manage/hiddenDanger";
import Dict from "./pages/manage/dict";
import Chemicals from "./pages/manage/chemicals";
import SpecialPlace from "./pages/manage/specialPlace";
import Report from "./pages/manage/convertToPdfTest";
import SafeCheckRecord from "./pages/manage/safe_check_page";
import FireAccidentRecord from "./pages/manage/fire_accident_record_page";
import FireRecord from "./pages/manage/fireRecord";
import AppointmentManage from "./pages/manage/appointment";
import Class from "./pages/manage/class";
import Score from "./pages/manage/score";
import Question from "./pages/manage/question";
import SuggestComplaint from "./pages/manage/suggestComplaint";

export default function Router() {
    return useRoutes([
        {
            path: '/',
            children: [
                // {path: '/', element: <Navigate to="/login" replace/>},
                {path: '/', element: <Navigate to="/home" replace/>},
                {path: 'login', element: <Login/>},
                {path: 'xc', element: <Layout/>},
                {path: 'personal', element: <Personal/>},
                {path: 'home', element: <HomePage/>},
                {path: 'map', element: <HydrantMap/>},
                {
                    path: 'safe_check_record',
                    element: <SafeCheckRecord/>,
                },
                {
                    path: 'fire_accident_record',
                    element: <FireAccidentRecord/>,
                },

            ]
        },
        {
            path: '/manage',
            element: <Layout/>,
            children: [
                // {path: '/user', element: },
                {path: 'hydrant', element: <Hydrant/>},
                {path: 'community', element: <Community/>},
                {path: 'company', element: <Company/>},
                {path: 'special_place', element: <SpecialPlace/>},
                {path: 'chemicals', element: <Chemicals/>},
                {
                    path: 'inspect_task', children: [
                        {path: '', element: <InspectTask/>},
                        {path: 'regular', element: <InspectTaskRegular/>}
                    ]
                },
                {path: 'exercise', element: <Exercise/>},
                {path: 'hiddenDanger', element: <HiddenDanger/>},
                {path: 'fireRecord', element: <FireRecord/>},
                {path: 'dept', element: <Dept/>},
                {path: 'menu', element: <Menu/>},
                {path: 'role', element: <Role/>},
                {path: 'user', element: <User/>},
                {path: 'area', element: <Area/>},
                {path: 'road', element: <Road/>},
                {path: 'dict', element: <Dict/>},
                {path: 'inspect_task', element: <InspectTask/>},
                {path: 'pdf_test', element: <Report/>},
                {
                    path: 'appointment', children: [
                        {path: 'record', element: <AppointmentManage/>},
                        {path: 'company', element: <Company/>},
                        {path: 'class', element: <Class/>},
                        {path: 'score', element: <Score/>},
                        {path: 'question', element: <Question/>},

                    ]
                },
                // {path: 'company', element: <Company/>},
                // {path: 'class', element: <Class/>},
                // {path: 'score', element: <Score/>},
                // {path: 'question', element: <Question/>},
                {
                    path: 'suggest_complaint',
                    element: <SuggestComplaint/>,
                }
            ]
        },

    ]);
};
