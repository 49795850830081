import React from 'react';
import {Button, Modal} from "antd";
import {Map, Marker} from "react-amap";
import styles from "./index.module.css"

const SignInInfoModal = ({open, onCancel, location, time}: any) => {
    return (
        <Modal onCancel={onCancel} footer={[
            <Button type={'primary'} key="back" onClick={onCancel}>
                知道了
            </Button>]} title="定位" width="50vw" open={open}>
            <div style={{width: '100%', height: "40vh"}}>
                <Map plugins={['ToolBar']} center={{longitude: location?.lng, latitude: location?.lat}} zoom={17}>
                    <Marker position={{longitude: location?.lng, latitude: location?.lat}}/>
                </Map>
            </div>
            <div className={styles.signInText}>打卡地点:{location?.address}</div>
            <div className={styles.signInText}>打卡时间:{location?.time}</div>
        </Modal>
    );
};

export default SignInInfoModal;