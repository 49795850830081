import {Button, Cascader, DatePicker, Form, Input, InputNumber, Modal, Select, TreeSelect} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
// @ts-ignore
import {debounce} from "lodash"
import {showFail, showNeedCompleteInfo, showNetError, showSuccess, showWarning} from "../functions";
import {addClass, addUser, getId} from "../../api";
import AddNewPictures from "../AddNewPictures";


const useResetFormOnCloseModal = ({form, open}: { form: any, open: boolean }) => {
    const prevOpenRef: any = useRef();
    useEffect(() => {
        prevOpenRef.current = open;
    }, [open]);
    const prevOpen = prevOpenRef.current;
    useEffect(() => {
        if (!open && prevOpen) {
            form.resetFields();
        }
    }, [form, prevOpen, open]);
};

const AddClassModal = ({
                           classNow,
                          open,
                          onClose,
                          handleGetClass
                      }: {
    classNow:any,open: boolean, onClose: () => void,
    handleGetClass: any
}) => {
    const [form] = Form.useForm();
    useResetFormOnCloseModal({
        form,
        open,
    });

    useEffect(() => {
        if (open) {
            form.resetFields();
            handleGetGeneratedBlockId()
        }
    }, [open])
    const [generatedBlockId, setGeneratedBlockId] = useState<string>("");
    const handleGetGeneratedBlockId = () => {
        getId().then((res: any) => {
            if (res?.code === 200) {
                setGeneratedBlockId(res?.data)
            }
        }).catch((err: any) => {
            console.log(err);
            showNetError();
        })
    }

    const [loading, setLoading] = useState<boolean>(false);
    const handleAddClass = () => {
        form.validateFields().then((value) => {
            // value.blockPictureUrl=value.blockPictureUrl[0].response.data
            console.log('value', value);
            setLoading(true);
            addClass(value).then((res: any) => {
                setLoading(false);
                console.log(res)
                if (res?.code === 200) {
                    handleGetClass().then(() => {
                        showSuccess(res?.msg);
                        onClose();
                    })
                } else {
                    showFail(res?.msg)
                }
            }).catch((err: any) => {
                console.log(err)
            })

        }).catch((err) => {
            console.log(err);
            showNeedCompleteInfo();
        })
    }

    const handleClassTimeChange=()=>{};


    return (
        <div>
            <Modal open={open} onCancel={() => {
                onClose();
                setLoading(false)
            }} width={'800px'} title={`考试新增`} maskClosable={false} okText={'确认提交'}
                   onOk={debounce(handleAddClass, 1000)} okButtonProps={{loading: loading}} destroyOnClose>
                <Form form={form} name="form" labelAlign="right" labelCol={{
                    xs: {span: 24},
                    sm: {span: 6},
                }} wrapperCol={{
                    xs: {span: 24},
                    sm: {span: 14},
                }}>
                    <Form.Item
                        name="blockName"
                        label="考试名称"
                        rules={[
                            {
                                required: true, message: "请输入考试名称"
                            },
                        ]}
                    >
                        <Input placeholder={'请输入考试名称'}/>
                    </Form.Item>

                    {/*<Form.Item*/}
                    {/*    name="blockDetail"*/}
                    {/*    label="考试简介"*/}
                    {/*    rules={[*/}
                    {/*        {*/}
                    {/*            required: true, message: "请输入考试简介"*/}
                    {/*        },*/}
                    {/*    ]}*/}
                    {/*>*/}
                    {/*    <Input.TextArea placeholder="请输入考试简介"/>*/}
                    {/*</Form.Item>*/}
                    <Form.Item
                        name="totalScore"
                        label="总分"
                        rules={[
                            {
                                required: true, message: "请输入总分"
                            },
                        ]}
                    >
                        <InputNumber />
                    </Form.Item>
                    <Form.Item
                        name="passScore"
                        label="合格分"
                        rules={[
                            {
                                required: true, message: "请输入合格分"
                            },
                        ]}
                    >
                        <InputNumber />
                    </Form.Item>
                    <Form.Item
                        name="examNum"
                        label="抽检题目数量"
                        rules={[
                            {
                                required: true, message: "请输入抽检题目数量"
                            },
                        ]}
                    >
                        <InputNumber />
                    </Form.Item>
                    {/*<Form.Item*/}
                    {/*    name="blockPictureUrl"*/}
                    {/*    label="封面照片"*/}
                    {/*    valuePropName="fileList"*/}
                    {/*>*/}
                    {/*    <AddNewPictures id={classNow?.blockId}  max={1}/>*/}
                    {/*</Form.Item>*/}

                    {/*<Form.Item*/}
                    {/*    name="mobile"*/}
                    {/*    label="学习时间"*/}
                    {/*    rules={[*/}
                    {/*        {*/}
                    {/*            required: true, message: "请选择学习时间"*/}
                    {/*        },*/}
                    {/*    ]}*/}
                    {/*>*/}
                    {/*    <DatePicker.RangePicker style={{width: "100%"}} showTime={{format: 'HH:mm'}}*/}
                    {/*                            format="YYYY-MM-DD HH:mm"*/}
                    {/*                            placeholder={['开始时间', '结束时间']}*/}
                    {/*                            onChange={handleClassTimeChange}/>*/}
                    {/*</Form.Item>*/}

                </Form>
            </Modal>
        </div>
    );
};

export default AddClassModal;