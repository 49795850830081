import request from './apiService.config';

//发送短信
export function sendSms(params:any) {
    return request({
        url: '/sysUser/sendSMS', method: 'get', params
    });
}

//登录
export function userlogin(data:any) {
    return request({
        url: '/sysUser/login', method: 'post', data
    });
}

//查询区域树
export function getAreaTree(params?:any) {
    return request({
        url: '/area/getTree', method: 'get', params
    });
}

//查询XHS(分页)
export function getHydrantPage(params:any) {
    return request({
        url: '/hydrant/getPage', method: 'get', params
    });
}

//查询XHS(非分页)
export function getHydrantList(params:any) {
    return request({
        url: '/hydrant/get', method: 'get', params
    });
}

//新增XHS
export function addHydrant(data:any) {
    return request({
        url: '/hydrant/add', method: 'post', data
    });
}

//删除XHS
export function deleteHydrant(data:any) {
    return request({
        url: '/hydrant/delete', method: 'post', data
    });
}

//修改XHS信息
export function updateHydrant(data:any) {
    return request({
        url: '/hydrant/update', method: 'put', data
    });
}

//地名关键词搜索
export function getMapLoc(params:any) {
    return request({
        url: '/map/getLoc', method: 'get', params
    });
}


//获取用户
export function getUser(params:any) {
    return request({
        url: '/sysUser/searchPage', method: 'get', params
    });
}

//新增任务
export function addInspectTask(data:any) {
    return request({
        url: '/inspectionTask/add', method: 'post', data
    });
}

//查询任务
export function getTaskPage(params:any) {
    return request({
        url: '/inspectionTask/getPage', method: 'get', params
    });
}

//删除任务
export function deleteTask(data:any) {
    return request({
        url: '/inspectionTask/delete', method: 'post', data
    });
}

//批量新增待巡检XHS
export function addInspectHydrantList(data:any) {
    return request({
        url: '/inspectionRecord/addList', method: 'post', data
    });
}

//查询巡检任务清单(分页)
export function getInspectRecordPage(params:any) {
    return request({
        url: '/inspectionRecord/getPage', method: 'get', params
    });
}

//查询巡检任务清单(非分页)
export function getInspectRecord(params:any) {
    return request({
        url: '/inspectionRecord/get', method: 'get', params
    });
}
export function getMenuTree(params:any) {
    return request({
        url: '/menu/searchTree', method: 'get', params, headers: {
            'Content-Type': 'application/json'
        }
    });
}

//增加权限
export function addMenu(data:any) {
    return request({
        url: '/menu/add', method: 'post', data, headers: {
            'Content-Type': 'application/json'
        }
    });
}

//删除权限
export function deleteMenu(params:any) {
    return request({
        url: '/menu/delete', method: 'delete', params, headers: {
            'Content-Type': 'application/json'
        }
    });
}

//修改权限
export function updateMenu(data:any) {
    return request({
        url: '/menu/update', method: 'put', data, headers: {
            'Content-Type': 'application/json'
        }
    });
}




//增加用户
export function addUser(data:any) {
    return request({
        url: '/sysUser/add', method: 'post', data, headers: {
            'Content-Type': 'application/json'
        }
    });
}

//删除用户
export function deleteUser(params:any) {
    return request({
        url: '/sysUser/deleteById', method: 'delete', params, headers: {
            'Content-Type': 'application/json'
        }
    });
}

//修改用户信息
export function updateUser(data:any) {
    return request({
        url: '/sysUser/update', method: 'put', data, headers: {
            'Content-Type': 'application/json'
        }
    });
}

//获取用户角色
export function searchForUser(params:any) {
    return request({
        url: '/role/searchForUser', method: 'get', params, headers: {
            'Content-Type': 'application/json'
        }
    });
}

//增加或修改用户角色
export function userRole(data:any) {
    return request({
        url: '/userRole/addOrUpdate', method: 'post', data, headers: {
            'Content-Type': 'application/json'
        }
    });
}

export function addRole(data:any) {
    return request({
        url: '/role/add', method: 'post', data, headers: {
            'Content-Type': 'application/json'
        }
    });
}

//获取角色
export function searchRole(params:any) {
    return request({
        url: '/role/search', method: 'get', params, headers: {
            'Content-Type': 'application/json'
        }
    });
}

//获取角色
export function searchAll(params:any) {
    return request({
        url: '/menu/searchAll', method: 'get', params, headers: {
            'Content-Type': 'application/json'
        }
    });
}


//删除角色
export function deleteRole(params:any) {
    return request({
        url: '/role/delete', method: 'delete', params, headers: {
            'Content-Type': 'application/json'
        }
    });
}


//修改角色信息
export function updateRole(data:any) {
    return request({
        url: '/role/update', method: 'put', data, headers: {
            'Content-Type': 'application/json'
        }
    });
}




export function updateroleMenu(data:any) {
    return request({
        url: 'roleMenu/addOrUpdate', method: 'post', data, headers: {
            'Content-Type': 'application/json'
        }
    });
}

export function getDeptById(params:any) {
    return request({
        url: '/dept/getDeptById', method: 'get', params, headers: {
            'Content-Type': 'application/json'
        }
    });
}

export function getDeptTree(params?:any) {
    return request({
        url: '/dept/searchTree', method: 'get', params, headers: {
            'Content-Type': 'application/json'
        }
    });
}

export function addDept(data:any) {
    return request({
        url: '/dept/add', method: 'post', data, headers: {
            'Content-Type': 'application/json'
        }
    });
}

export function deleteDept(params:any) {
    return request({
        url: '/dept/delete', method: 'delete', params, headers: {
            'Content-Type': 'application/json'
        }
    });
}

export function updateDept(data:any) {
    return request({
        url: '/dept/update', method: 'put', data, headers: {
            'Content-Type': 'application/json'
        }
    });
}
export function searchUserById(params:any) {
    return request({
        url: 'sysUser/searchById', method: 'get', params, headers: {
            'Content-Type': 'application/json'
        }
    });
}
export function searchByMobile(params:any) {
    return request({
        url: 'sysUser/searchByMobile', method: 'get', params, headers: {
            'Content-Type': 'application/json'
        }
    });
}

export function searchById(params:any) {
    return request({
        url: 'maintenanceStaff/searchById', method: 'get', params, headers: {
            'Content-Type': 'application/json'
        }
    });
}


export function searchDict(params:any) {
    return request({
        url: 'dict/search', method: 'get', params, headers: {
            'Content-Type': 'application/json'
        }
    });
}

//批量删除巡检记录
export function deleteInspectRecord(data:any) {
    return request({
        url: '/inspectionRecord/deleteList', method: 'post', data
    });
}

//查询隐患(分页)
export function getHiddenDangerPage(params:any) {
    return request({
        url: '/hiddenDanger/getPage', method: 'get', params
    });
}

//查询定期计划
export function getRegularInspectionPlanPage(params:any) {
    return request({
        url: '/regularInspection/getPage', method: 'get', params
    });
}

//新增定期计划
export function addRegularInspectPlan(data:any) {
    return request({
        url: '/regularInspection/add', method: 'post', data
    });
}


export function addArea(data:any) {
    return request({
        url: '/area/add', method: 'post', data
    });
}

export function deleteArea(data:any) {
    return request({
        url: '/area/delete', method: 'post', data
    });
}

export function updateArea(data:any) {
    return request({
        url: '/area/update', method: 'post', data
    });
}

//获取用户(不分页)
export function getUserList(params:any) {
    return request({
        url: '/sysUser/search', method: 'get', params
    });
}

//删除巡检计划
export function deleteRegularInspectPlan(data:any) {
    return request({
        url: '/regularInspection/delete', method: 'post', data
    });
}

//查询巡检清单(周期计划,分页)
export function getRegularRecord(params:any) {
    return request({
        url: '/regularHydrant/getPage', method: 'get', params
    });
}

//修改巡检任务
export function updateInspectTask(data:any) {
    return request({
        url: '/inspectionTask/update', method: 'put', data
    });
}


//查询周期计划巡检任务清单(非分页)
export function getRegularInspectRecord(params:any) {
    return request({
        url: '/regularHydrant/get', method: 'get', params
    });
}

//周期计划批量新增清单
export function addRegularHydrantList(data:any) {
    return request({
        url: '/regularHydrant/addList', method: 'post', data
    });
}

//周期计划批量删除清单
export function deleteRegularHydrantList(data:any) {
    return request({
        url: '/regularHydrant/deleteList', method: 'post', data
    });
}

//更新周期计划
export function updateRegularPlan(data:any) {
    return request({
        url: '/regularInspection/update', method: 'post', data
    });
}

//手动发布任务
export function releaseInspectTask(data:any) {
    return request({
        url: '/inspectionTask/manRelease', method: 'put', data
    });
}

//手动发布定期计划
export function releaseRegularPlan(data:any) {
    return request({
        url: '/regularInspection/release', method: 'post', data
    });
}

//经纬度解析
export function getMapAddress(params:any) {
    return request({
        url: '/map/getAddress', method: 'get', params
    });
}

//经纬度解析
export function getRoads(params?:any) {
    return request({
        url: '/road/get', method: 'get', params
    });
}

//任务报告
export function getTaskDoc(params?:any) {
    return request({
        url: '/inspectionTask/getTaskDoc', method: 'get', params
    });
}

//整改消防栓报告
export function getHydrantDoc(data?:any) {
    return request({
        url: '/hydrant/getHydrantDoc', method: 'post', data
    });
}

//生成XHS最近的巡检记录报告
export function getAllHydrantDoc(params?:any) {
    return request({
        url: '/hydrant/getAllHydrantDoc', method: 'get', params
    });
}


//查询道路
export function getAllRoad(params?:any) {
    return request({
        url: '/road/getPage', method: 'get', params
    });
}

//新增道路
export function addRoad(data?:any) {
    return request({
        url: '/road/add', method: 'post', data
    });
}

//删除道路
export function deleteRoad(data?:any) {
    return request({
        url: '/road/delete', method: 'post', data
    });
}

//查询社区(分页)
export function getCommunityPage(params?:any) {
    return request({
        url: '/community/getPage', method: 'get', params
    });
}

//删除社区
export function deleteCommunity(data?:any) {
    return request({
        url: '/community/delete', method: 'post', data
    });
}

//查询社区(未分页)
export function getCommunity(params?:any) {
    return request({
        url: '/community/get', method: 'get', params
    });
}

//删除标记点
export function deleteCommunityPoint(data?:any) {
    return request({
        url: '/communityPoint/delete', method: 'post', data
    });
}

//删除联系人
export function deleteCommunityContact(data?:any) {
    return request({
        url: '/communityContact/delete', method: 'post', data
    });
}

//新增点位
export function addPoint(data?:any) {
    return request({
        url: '/communityPoint/add', method: 'post', data
    });
}

//更新点位
export function updatePoint(data?:any) {
    return request({
        url: '/communityPoint/update', method: 'post', data
    });
}

//新增联系人
export function addContact(data?:any) {
    return request({
        url: '/communityContact/add', method: 'post', data
    });
}

//查询联系人
export function getContact(params?:any) {
    return request({
        url: '/communityContact/get', method: 'get', params
    });
}

//修改联系人
export function updateContact(data?:any) {
    return request({
        url: '/communityContact/update', method: 'post', data
    });
}

//生成社区id
export function getCommunityId(params?:any) {
    return request({
        url: '/community/getId', method: 'get', params
    });
}

//新增社区
export function addCommunity(data:any) {
    return request({
        url: '/community/add', method: 'post', data
    });
}

//修改社区
export function updateCommunity(data:any) {
    return request({
        url: '/community/update', method: 'post', data
    });
}


//生成XHSid
export function getHydrantId(params?:any) {
    return request({
        url: '/hydrant/getId', method: 'get', params
    });
}

//查询演练数据(分页)
export function getExerciseDataPage(params?:any) {
    return request({
        url: '/exercise/getPage', method: 'get', params
    });
}

//查询演练数据
export function getExerciseData(params?:any) {
    return request({
        url: '/exercise/get', method: 'get', params
    });
}

//新增隐患任务
export function addHiddenDangerTask(data:any) {
    return request({
        url: '/hiddenDanger/add', method: 'post', data
    });
}


//新增演练学习任务
export function addExercise(data:any) {
    return request({
        url: '/exercise/add', method: 'post', data
    });
}

//获取隐患数据(分页)
export function getHiddenDangerDataPage(params?:any) {
    return request({
        url: '/hiddenDanger/getPage', method: 'get', params
    });
}

//查询演练数据
export function getHiddenDangerData(params?:any) {
    return request({
        url: '/hiddenDanger/get', method: 'get', params
    });
}

export function getFireRecordDataPage(params?:any) {
    return request({
        url: '/fireRecord/searchPage', method: 'get', params
    });
}
export function getFireRecordData(params?:any) {
    return request({
        url: '/fireRecord/searchNotPage', method: 'get', params
    });
}

//查询字典数据
export function getDictData(params?:any) {
    return request({
        url: '/dict/get', method: 'get', params
    });
}

//查询字典数据(分页)
export function getDictPageData(params?:any) {
    return request({
        url: '/dict/getPage', method: 'get', params
    });
}

//新增字典
export function addDict(data:any) {
    return request({
        url: '/dict/add', method: 'post', data
    });
}

//删除字典
export function deleteDict(data:any) {
    return request({
        url: '/dict/delete', method: 'post', data
    });
}

//修改字典
export function updateDict(data:any) {
    return request({
        url: '/dict/update', method: 'post', data
    });
}


//查询危化品(分页)
export function getChemicalsPageData(params?:any) {
    return request({
        url: '/chemicals/getPage', method: 'get', params
    });
}


//修改危化品名称
export function updateChemicalsName(data?:any) {
    return request({
        url: '/chemicals/update', method: 'post', data
    });
}

//删除危化品
export function deleteChemicals(data?:any) {
    return request({
        url: '/chemicals/delete', method: 'post', data
    });
}

//删除隐患排查任务
export function deleteDangerTask(data?:any) {
    return request({
        url: '/hiddenDanger/delete', method: 'post', data
    });
}

//删除演练学习任务
export function deleteExerciseTask(data?:any) {
    return request({
        url: '/exercise/delete', method: 'post', data
    });
}


export function getAllCompany(params?: any) {
    return request({
        url: '/company/searchPage', method: 'get', params
    });
}

export function getAllCompanyNotPage(params?: any) {
    return request({
        url: '/company/searchNotPage', method: 'get', params
    });
}

export function deleteCompany(data?: any) {
    return request({
        url: '/company/delete/'+data?.companyId, method: 'post', data
    });
}

export function addCompany(data?: any) {
    return request({
        url: '/company/add', method: 'post', data
    });
}

export function updateCompany(data?: any) {
    return request({
        url: '/company/update', method: 'post', data
    });
}

export function downloadQrcode(data?: any) {
    return request({
        url: '/company/downloadQrcode', method: "post", data,
    });
}
//获取二维码
export function getQrcode(params?: any) {
    return request({
        url: '/company/getQrcode', method: "get", params,
    });
}


export function addClass(data?:any) {
    return request({
        url: `/block/add`, method: 'post',data
    });
}

export function updateClass(data?:any) {
    return request({
        url: `/block/update`, method: 'post',data
    });
}

export function deleteClass(data?:any) {
    return request({
        url: `/block/delete`, method: 'post',data
    });
}


export function getClasses(params?:any) {
    return request({
        url: '/block/getPage', method: 'get', params
    });
}


export function getId(params?:any) {
    return request({
        url: '/block/getId', method: 'get', params
    });
}



export function getQuestion(params?:any) {
    return request({
        url: '/question/searchPage', method: 'get', params
    });
}

//获取预约情况
export function getAppointmentPage(params?:any) {
    return request({
        url: '/appointment/getPage', method: 'get', params
    });
}

export function getAppointmentNotPage(params?:any) {
    return request({
        url: '/appointment/get', method: 'get', params
    });
}


export function getAllStreets(params?:any) {
    return request({
        url: '/area/getAllStreets', method: 'get', params
    });
}

export function geUserExamPage(params?: any) {
    return request({
        url: '/sysUserExam/getPage', method: "get", params,
    });
}


export function getSuggestPage(params?: any) {
    return request({
        url: '/suggest/getPage', method: "get", params,
    });
}

export function getSuggestById(params?: any) {
    return request({
        url: '/suggest/getById', method: "get", params,
    });
}

export function getUserByRole(params?: any) {
    return request({
        url: '/sysUser/searchByRole', method: "get", params,
    });
}

//判断人员是否有空
export function judgeIdle(data?: any) {
    return request({
        url: '/appointment/judgeIdle', method: "post", data,
    });
}

//人员指派
export function assignTaskMember(data?: any) {
    return request({
        url: '/appointment/selectHandler', method: "post", data,
    });
}

//部门转派
export function reDispatchDept(data?: any) {
    return request({
        url: '/appointment/reDispatch', method: "post", data,
    });
}

//退回至支队
export function retreatToLeadTeam(data?: any) {
    return request({
        url: '/appointment/launchDispatch', method: "post", data,
    });
}