import {Button, DatePicker, Drawer, Image, Rate, Spin} from 'antd';
import React, {useEffect, useState} from 'react';
// @ts-ignore
import {debounce} from "lodash";
import styles from "./index.module.css"
import {Map, Marker} from "react-amap";
import dayjs from 'dayjs';
import AssignAppointmentMemberModal from "../assignAppointmentMemberModal";
import expandIcon from "../../assets/icons/expand.svg"
import foldUpIcon from "../../assets/icons/foldUp.svg"
import SignInInfoModal from "../signInInfoModal";
import rightBlueIcon from "../../assets/icons/right_blue.svg"
import {getAppointmentNotPage, getHiddenDangerData} from "../../api";
import {showFail, showWaringOfCustomDuration, showWarning} from "../functions";
import RepickAppointmentUser from "../repickAppointmentUser";
import ReAssignDeptModal from "../reAssignDeptModal";
import AppointmentRetreatModal from "../appointmentRetreatModal";

const AppointmentDetailDrawer = ({appointmentId, open, onCancel, handleGetAppointment}: any) => {
    let isSuperManager: any = JSON.parse(localStorage.getItem("isSuperManager") as string);

    console.log('isSuperManager', isSuperManager)
    let isAreaManager = JSON.parse(localStorage.getItem("isAreaManager") as string);
    let sysUser = JSON.parse(localStorage.getItem("sysUser") as string);


    const [loading, setLoading] = useState<boolean>(false);
    const [appointmentRecord, setAppointmentRecord] = useState<any>({});
    const [signInTotalNumber, setSignInTotalNumber] = useState<number>(0);
    const [signInFinishedNumber, setSignInFinishedNumber] = useState<number>(0);
    const [assignModalOpen, setAssignModalOpen] = useState<boolean>(false);
    const handleAssignModalCancel = () => {
        setAssignModalOpen(false);
    }

    const handleGetAppointmentRecord = async (appointmentId: string) => {
        setLoading(true);
        getAppointmentNotPage({appointmentId}).then((res: any) => {

            if (res?.code === 200) {
                let _appointmentRecord = res?.data?.[0];
                let supervisor_part_count = _appointmentRecord?.supervisionUser ? 1 : 0;
                let publicize_part_count = _appointmentRecord?.publicizeUser ? 1 : 0;
                let safe_part_count = _appointmentRecord?.safeUser ? 1 : 0;
                let _signInTotalNumber = supervisor_part_count + publicize_part_count + safe_part_count;
                setSignInTotalNumber(_signInTotalNumber);

                let supervisor_finish_count = _appointmentRecord?.supervisionClockIn ? 1 : 0;
                let publicize_finish_count = _appointmentRecord.publicizeClockIn ? 1 : 0;
                let safe_finish_count = _appointmentRecord.safeClockIn ? 1 : 0;
                let _signInFinishedNumber = supervisor_finish_count + publicize_finish_count + safe_finish_count;
                setAppointmentRecord(_appointmentRecord);
                setSignInFinishedNumber(_signInFinishedNumber);

                let examTotalNumber = appointmentRecord?.totalNum ? Number(appointmentRecord?.totalNum) : 0;
                let examPassNumber = appointmentRecord?.passNum ? Number(appointmentRecord?.passNum) : 0;

                let itemWidth = examTotalNumber > 20 ? 100 / examTotalNumber * (0.8) : 2;
                let breakWidth = examTotalNumber > 20 ? 100 / examTotalNumber * (0.2) : 0.5;

                setExamStatics({
                    totalNumber: examTotalNumber,
                    passNumber: examPassNumber,
                    itemWidth,
                    breakWidth
                });
            } else {
                showFail(res?.msg)
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setLoading(false);
        })
    }

    const [showAppointmentDetailArea, setShowAppointmentDetailArea] = useState<boolean>(true);

    const [showSignInArea, setShowSignInArea] = useState<boolean>(true);

    const [signInInfoModalOpen, setSignInInfoModalOpen] = useState<boolean>(false);
    const [signInLocation, setSignInLocation] = useState<any>({lng: 120, lat: 30, address: ""})
    const handleSignInLocationCancel = () => {
        setSignInInfoModalOpen(false);
    }

    const [showTaskDetailArea, setShowTaskDetailArea] = useState<boolean>(true);
    const [examStatics, setExamStatics] = useState<any>(
        {
            totalNumber: 0,
            passNumber: 0,
            itemWidth: 0 as number,
            breakWidth: 0 as number,
        }
    );

    useEffect(() => {
        if (open) {
            // let itemWidth = examStatics.totalNumber > 20 ? 100 / examStatics.totalNumber * (0.8) : 2;
            // let breakWidth = examStatics.totalNumber > 20 ? 100 / examStatics.totalNumber * (0.2) : 0.5;
            // setExamStatics({
            //     ...examStatics,
            //     itemWidth,
            //     breakWidth
            // });
            handleGetAppointmentRecord(appointmentId);
        }

    }, [open]);

    const handleDangerTableClick = (dangerId: any) => {
        getHiddenDangerData({
            dangerId
        }).then((res: any) => {
            let record = res?.data[0];
            let dangerState = record?.dangerState;
            if (dangerState === 9) {
                showWaringOfCustomDuration("防消联勤人员暂未提交表格!", 3)
            } else {
                window.open("/#/safe_check_record?dangerId=" + dangerId, '_blank');
            }
        })
    };

    const rateDesc = ['1分', '2分', '3分', '4分', '5分', '6分', '7分', '8分', '9分', '10分'];

    const [repickInitUser, setRepickInitUser] = useState<any[]>([]);
    const [repickUserType, setRepickUserType] = useState<any>();
    const [repickAppointmentUserModalOpen, setRepickAppointmentUserModalOpen] = useState<boolean>(false);
    const handleCloseRepickAppointmentUserModal = () => {
        setRepickAppointmentUserModalOpen(false);
    }

    const [reAssignDeptModalOpen, setReAssignDeptModalOpen] = useState<boolean>(false);
    const handleCloseReAssignDeptModal = () => {
        setReAssignDeptModalOpen(false);
    }

    const [retreatModalOpen, setRetreatModalOpen] = useState<boolean>(false);
    const handleCloseRetreatModal = () => {
        setRetreatModalOpen(false);
    }

    const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false);
    const handleCloseCancelModal = () => {
        setCancelModalOpen(false);
    }

    return (
        <Drawer className={styles.drawer} width={'50vw'} open={open}
                onClose={onCancel} destroyOnClose maskClosable={false}
                title={`预约单详情`}
        >
            <Spin spinning={loading}>

                <div className={styles.block}>
                    <div>
                        <div className={styles.blockTitle}>预约信息</div>

                        <div className={styles.infoItem}>
                            <div className={styles.infoTitle}>
                                单位名称
                            </div>
                            <div className={styles.infoContent}>
                                {appointmentRecord?.unitName}
                            </div>
                        </div>

                        {showAppointmentDetailArea && (
                            <>
                                <div className={styles.infoItem}>
                                    <div className={styles.infoTitle}>
                                        预约单状态
                                    </div>
                                    <div className={styles.infoContent}>
                                        {appointmentRecord?.selectState}
                                    </div>
                                </div>

                                {appointmentRecord.selectState === '作废' && (
                                    <div className={styles.infoItem}>
                                        <div className={styles.infoTitle}>
                                            作废类别
                                        </div>
                                        <div className={styles.infoContent}>
                                            {appointmentRecord?.appointmentState}
                                        </div>
                                    </div>
                                )}


                                <div className={styles.infoItem}>
                                    <div className={styles.infoTitle}>
                                        所属县区
                                    </div>
                                    <div className={styles.infoContent}>
                                        {appointmentRecord?.district?.name}

                                    </div>
                                </div>

                                <div className={styles.infoItem}>
                                    <div className={styles.infoTitle}>
                                        所属街道
                                    </div>
                                    <div className={styles.infoContent}>
                                        {appointmentRecord?.street?.name}
                                    </div>
                                </div>

                                <div className={styles.infoItem}>
                                    <div className={styles.infoTitle}>
                                        接收支队
                                    </div>
                                    <div className={styles.infoContent}>
                                        {appointmentRecord?.sysDept?.name}
                                        {appointmentRecord.appointmentState === '重派' && (
                                            <div
                                                style={{color: "red"}}>已发起重派,支队处理中,原因:{appointmentRecord.redispatchReason}</div>
                                        )}
                                    </div>
                                </div>


                                <div className={styles.infoItem}>
                                    <div className={styles.infoTitle}>
                                        详细地址
                                    </div>
                                    <div className={styles.infoContent}>
                                        {appointmentRecord?.unitAddress}
                                    </div>
                                </div>

                                {appointmentRecord?.unitLongitude && appointmentRecord?.unitLatitude && (
                                    <div className={styles.infoItem}>
                                        <div className={styles.infoTitle}>
                                            定位
                                        </div>
                                        <div className={styles.infoContent}
                                             style={{width: "85%", height: 300, marginTop: 5}}>
                                            <Map plugins={['ToolBar']} center={{
                                                longitude: appointmentRecord?.unitLongitude,
                                                latitude: appointmentRecord?.unitLatitude
                                            }}
                                                 zoom={17}>
                                                <Marker position={{
                                                    longitude: appointmentRecord?.unitLongitude,
                                                    latitude: appointmentRecord?.unitLatitude
                                                }}/>
                                            </Map>
                                        </div>
                                    </div>
                                )}

                                {
                                    appointmentRecord?.startDate && appointmentRecord?.endDate && (
                                        <div className={styles.infoItem} style={{marginTop: 10}}>
                                            <div className={styles.infoTitle}>
                                                预约时间段
                                            </div>
                                            <div className={styles.infoContent}>
                                                <DatePicker.RangePicker
                                                    defaultValue={[dayjs(appointmentRecord?.startDate, "YYYY-MM-DD"), dayjs(appointmentRecord?.endDate, "YYYY-MM-DD")]}
                                                    disabled={[true, true]}
                                                />
                                            </div>
                                        </div>
                                    )
                                }

                                <div className={styles.infoItem}>
                                    <div className={styles.infoTitle}>
                                        发起人
                                    </div>
                                    <div className={styles.infoContent}>
                                        {appointmentRecord?.applicantName} {appointmentRecord?.loginPhoneNumber}
                                    </div>
                                </div>

                                <div className={styles.infoItem}>
                                    <div className={styles.infoTitle}>
                                        发起时间
                                    </div>
                                    <div className={styles.infoContent}>
                                        {appointmentRecord?.createTime}
                                    </div>
                                </div>
                            </>
                        )}

                        <div className={styles.blockBottom}>
                            <img width={20} height={20} src={showAppointmentDetailArea ? foldUpIcon : expandIcon}
                                 alt=""/>
                            <div>
                                <Button onClick={() => {
                                    setShowAppointmentDetailArea(!showAppointmentDetailArea);
                                }} type={'link'}>{showAppointmentDetailArea ? "收起" : "展开"}详细信息</Button>
                            </div>
                        </div>
                    </div>
                </div>

                {appointmentRecord.selectState === '已分配' && (
                    <div className={styles.block}>
                        <div>
                            <div className={styles.blockTitle}>打卡情况</div>

                            <div className={styles.infoItem}>
                                <div className={styles.infoTitle}>
                                    打卡统计
                                </div>
                                <div className={styles.infoContent}>
                                    <div className={styles.signInProcessArea}>
                                        {Array.from({length: signInTotalNumber})?.map((s: any, index: number) => (
                                            <div key={index}
                                                 className={index + 1 > signInFinishedNumber ? styles.signProcessBlockError : styles.signProcessBlockSuccess}/>
                                        ))}
                                        <div
                                            className={styles.signInProcessStatics}>{signInFinishedNumber}/{signInTotalNumber}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {showSignInArea && (
                                <>
                                    {appointmentRecord?.supervisionUser && (
                                        <div className={styles.infoItem}>
                                            <div className={styles.infoTitle}>
                                                监督员
                                            </div>
                                            <div className={styles.infoContent}>
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center'
                                                }}>
                                                    <div
                                                        style={{width: '200px'}}>{appointmentRecord?.supervisionUser?.username} {appointmentRecord?.supervisionUser?.mobile}</div>
                                                    {!appointmentRecord?.supervisionClockIn ? (
                                                        <div style={{
                                                            width: '180px',
                                                            fontSize: '12px',
                                                            color: 'red'
                                                        }}>未打卡</div>
                                                    ) : (
                                                        <div style={{width: '180px', fontSize: '12px'}}>已打卡</div>
                                                    )}

                                                    {appointmentRecord?.supervisionClockIn && (
                                                        <div>
                                                            <Button type={'primary'} size={'small'} onClick={() => {
                                                                setSignInInfoModalOpen(true);
                                                                setSignInLocation({
                                                                    lng: appointmentRecord.supervisionClockLng,
                                                                    lat: appointmentRecord.supervisionClockLat,
                                                                    address: appointmentRecord.supervisionClockAddress,
                                                                    time: appointmentRecord.supervisionClockInTime
                                                                });
                                                            }}>查看</Button>
                                                        </div>
                                                    )}

                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {appointmentRecord?.publicizeUser && (
                                        <div className={styles.infoItem}>
                                            <div className={styles.infoTitle}>
                                                宣传员
                                            </div>
                                            <div className={styles.infoContent}>
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center'
                                                }}>
                                                    <div
                                                        style={{width: '200px'}}>{appointmentRecord?.publicizeUser?.username} {appointmentRecord?.publicizeUser?.mobile}</div>
                                                    {!appointmentRecord?.publicizeClockIn ? (
                                                        <div style={{
                                                            width: '180px',
                                                            fontSize: '12px',
                                                            color: 'red'
                                                        }}>未打卡</div>
                                                    ) : (
                                                        <div style={{width: '180px', fontSize: '12px'}}>已打卡</div>
                                                    )}

                                                    {appointmentRecord?.publicizeClockIn && (
                                                        <div>
                                                            <Button type={'primary'} size={'small'} onClick={() => {
                                                                setSignInInfoModalOpen(true);
                                                                setSignInLocation({
                                                                    lng: appointmentRecord.publicizeClockLng,
                                                                    lat: appointmentRecord.publicizeClockLat,
                                                                    address: appointmentRecord.publicizeClockAddress,
                                                                    time: appointmentRecord.publicizeClockInTime
                                                                });
                                                            }}>查看</Button>
                                                        </div>
                                                    )}

                                                </div>
                                            </div>
                                        </div>
                                    )}


                                    {appointmentRecord?.safeUser && (
                                        <div className={styles.infoItem}>
                                            <div className={styles.infoTitle}>
                                                防消联勤人员
                                            </div>
                                            <div className={styles.infoContent}>
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center'
                                                }}>
                                                    <div
                                                        style={{width: '200px'}}>{appointmentRecord?.safeUser?.username} {appointmentRecord?.safeUser?.mobile}</div>
                                                    {!appointmentRecord?.safeClockIn ? (
                                                        <div style={{
                                                            width: '180px',
                                                            fontSize: '12px',
                                                            color: 'red'
                                                        }}>未打卡</div>
                                                    ) : (
                                                        <div style={{width: '180px', fontSize: '12px'}}>已打卡</div>
                                                    )}

                                                    {appointmentRecord?.safeClockIn && (
                                                        <div>
                                                            <Button type={'primary'} size={'small'} onClick={() => {
                                                                setSignInInfoModalOpen(true);
                                                                setSignInLocation({
                                                                    lng: appointmentRecord.safeClockLng,
                                                                    lat: appointmentRecord.safeClockLat,
                                                                    address: appointmentRecord.safeClockAddress,
                                                                    time: appointmentRecord.safeClockInTime
                                                                });
                                                            }}>查看</Button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                    )}
                                </>
                            )}

                            <div className={styles.blockBottom}>
                                <img width={20} height={20} src={showSignInArea ? foldUpIcon : expandIcon}
                                     alt=""/>
                                <div>
                                    <Button onClick={() => {
                                        setShowSignInArea(!showSignInArea);
                                    }} type={'link'}>{showSignInArea ? "收起" : "展开"}详细信息</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {appointmentRecord.selectState === '已分配' && (
                    <div className={styles.block}>
                        <div>
                            <div className={styles.blockTitle}>任务信息</div>

                            <div className={styles.infoItem}>
                                <div className={styles.infoTitle}>
                                    任务时间
                                </div>
                                <div className={styles.infoContent}>
                                    {appointmentRecord.confirmDate.slice(0, 11)} {appointmentRecord.confirmDateExtend}
                                </div>
                            </div>

                            {showTaskDetailArea && (
                                <>
                                    <div className={styles.infoItem}>
                                        <div className={styles.infoTitle}>
                                            当前阶段
                                        </div>
                                        <div className={styles.infoContent}>
                                            未开始
                                        </div>
                                    </div>

                                    {appointmentRecord?.supervisionUser && (
                                        <div className={styles.infoItem} style={{alignItems: 'center'}}>
                                            <div className={styles.infoTitle}>
                                                监督员
                                            </div>
                                            <div className={styles.infoContent}>
                                                {appointmentRecord?.supervisionUser?.username} {appointmentRecord?.supervisionUser?.mobile}
                                                {appointmentRecord.selectState === '已分配' && !appointmentRecord.supervisionClockIn && isAreaManager && appointmentRecord.deptId === sysUser?.deptId && (
                                                    <Button size={'small'} style={{marginLeft: 40}} type={'primary'}
                                                            onClick={() => {
                                                                setRepickAppointmentUserModalOpen(true);
                                                                setRepickUserType(1);
                                                                setRepickInitUser([{
                                                                    ...appointmentRecord?.supervisionUser,
                                                                    taskUserType: 1
                                                                }])
                                                            }}>转派</Button>
                                                )}
                                            </div>

                                        </div>
                                    )}

                                    {appointmentRecord?.publicizeUser && (
                                        <div className={styles.infoItem}>
                                            <div className={styles.infoTitle}>
                                                宣传员
                                            </div>
                                            <div className={styles.infoContent}>
                                                {appointmentRecord?.publicizeUser?.username} {appointmentRecord?.publicizeUser?.mobile}
                                                {appointmentRecord.selectState === '已分配' && !appointmentRecord.publicizeClockIn && isAreaManager && appointmentRecord.deptId === sysUser?.deptId && (
                                                    <Button size={'small'} style={{marginLeft: 40}} type={'primary'}
                                                            onClick={() => {
                                                                setRepickAppointmentUserModalOpen(true);
                                                                setRepickUserType(2);
                                                                setRepickInitUser([{
                                                                    ...appointmentRecord?.publicizeUser,
                                                                    taskUserType: 2
                                                                }])
                                                            }}>转派</Button>
                                                )}
                                            </div>
                                        </div>
                                    )}

                                    {appointmentRecord?.safeUser && (
                                        <div className={styles.infoItem}>
                                            <div className={styles.infoTitle}>
                                                防消联勤人员
                                            </div>
                                            <div className={styles.infoContent}>
                                                {appointmentRecord?.safeUser?.username} {appointmentRecord?.safeUser?.mobile}
                                                {appointmentRecord.selectState === '已分配' && !appointmentRecord.safeClockIn && isAreaManager && appointmentRecord.deptId === sysUser?.deptId && (
                                                    <Button size={'small'} style={{marginLeft: 40}} type={'primary'}
                                                            onClick={() => {
                                                                setRepickAppointmentUserModalOpen(true);
                                                                setRepickUserType(2);
                                                                setRepickInitUser([{
                                                                    ...appointmentRecord?.safeUser,
                                                                    taskUserType: 3
                                                                }])
                                                            }}>转派</Button>
                                                )}
                                            </div>
                                        </div>
                                    )}

                                    <div className={styles.infoItem}>
                                        <div className={styles.infoTitle}>
                                            培训情况
                                        </div>
                                        <div className={styles.infoContent}>
                                            <div className={styles.trainingBlockArea}>
                                                {/*<div className={styles.blockInfoItem}>*/}
                                                {/*    <div className={styles.blockInfoTitle}>*/}
                                                {/*        培训内容*/}
                                                {/*    </div>*/}
                                                {/*    <div className={styles.blockInfoContent}>*/}
                                                {/*        消防知识宣贯*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}

                                                <div className={styles.blockInfoItem}>
                                                    <div className={styles.blockInfoTitle}>
                                                        培训照片
                                                    </div>
                                                    <div className={styles.blockInfoContent}>
                                                        {appointmentRecord?.appointmentPictureList?.length > 0 ? (
                                                            <div className={styles.imageArea}>
                                                                {appointmentRecord?.appointmentPictureList?.map((d: any) => (
                                                                    <div style={{marginRight: 10, marginBottom: 10}}>
                                                                        <Image
                                                                            width={100}
                                                                            src={d?.url}
                                                                        />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        ) : (
                                                            <div className={styles.imageArea}>
                                                                暂未上传培训照片
                                                            </div>
                                                        )}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.infoItem}>
                                        <div className={styles.infoTitle}>
                                            考试情况
                                        </div>
                                        <div className={styles.infoContent}>
                                            <div className={styles.trainingBlockArea}>
                                                <div className={styles.blockInfoItem}>
                                                    <div className={styles.blockInfoTitle}>
                                                        考试二维码
                                                    </div>
                                                    <div className={styles.blockInfoContent}>
                                                        <div style={{display: 'grid', placeItems: 'center'}}>
                                                            <Image
                                                                width={100}
                                                                src={"https://www.huxf.com.cn/download/qrCodeImage/17/174ce0867a-d732-45ea-a5d0-0538916e97a8.png"}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={styles.blockInfoItem}>
                                                    <div className={styles.blockInfoTitle}>
                                                        考试统计
                                                    </div>
                                                    <div className={styles.blockInfoContent}>
                                                        <div>
                                                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                                                通过人数/考试人数:
                                                                <div
                                                                    style={{color: '#245FE2'}}>{examStatics.passNumber}</div>
                                                                人/
                                                                <div
                                                                    style={{color: "#245FE2"}}> {examStatics.totalNumber}</div>
                                                                人
                                                                <div><Button size={'small'} type={'primary'}
                                                                             style={{marginLeft: 15}}>查看详情</Button>
                                                                </div>
                                                            </div>

                                                            {examStatics.totalNumber > 0 && (
                                                                <div className={styles.examStaticsArea}>
                                                                    {Array.from({length: examStatics.totalNumber})?.map((d: any, index: number) => (
                                                                        <div
                                                                            className={index + 1 > examStatics.passNumber ? styles.passItemDefault : styles.passItemSuccess}
                                                                            style={{
                                                                                width: `${examStatics.itemWidth}%`,
                                                                                marginRight: `${examStatics.breakWidth}%`
                                                                            }}
                                                                        />
                                                                    ))}

                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.infoItem}>
                                        <div className={styles.infoTitle}>
                                            防消联勤检查情况
                                        </div>
                                        <div className={styles.infoContent}>
                                            <div className={styles.trainingBlockArea}>
                                                {
                                                    appointmentRecord?.dangerId ? (
                                                        <div onClick={() => {
                                                            handleDangerTableClick(appointmentRecord?.dangerId)
                                                        }} className={styles.safeRecordItem}>
                                                            <div>防消联勤检查表</div>
                                                            <div>
                                                                <img style={{width: 30, height: 30}}
                                                                     src={rightBlueIcon} alt=""/>
                                                            </div>
                                                        </div>

                                                    ) : (
                                                        <div className={styles.safeRecordItem}>
                                                            <div>暂未完成检查</div>
                                                        </div>

                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            <div className={styles.blockBottom}>
                                <img width={20} height={20} src={showTaskDetailArea ? foldUpIcon : expandIcon}
                                     alt=""/>
                                <div>
                                    <Button onClick={() => {
                                        setShowTaskDetailArea(!showTaskDetailArea);
                                    }} type={'link'}>{showTaskDetailArea ? "收起" : "展开"}详细信息</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {(isSuperManager || sysUser?.mobile === appointmentRecord.loginPhoneNumber) && appointmentRecord.selectState === '已分配' && appointmentRecord.rate && (
                    <div className={styles.block}>
                        <div>
                            <div className={styles.blockTitle}>评价信息</div>

                            <div className={styles.infoItem}>
                                <div className={styles.infoTitle}>
                                    满意度评价
                                </div>
                                <div className={styles.infoContent}>
                                    <Rate count={10} disabled value={appointmentRecord?.rate}/>
                                    {appointmentRecord?.rate ? <span
                                        style={{marginLeft: 10}}>{rateDesc[appointmentRecord?.rate - 1]}</span> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div style={{height: '5vh'}}/>

                {appointmentRecord.selectState === '未分配' && appointmentRecord.appointmentState !== '重派' && isAreaManager && appointmentRecord.deptId === sysUser?.deptId && (
                    <div>
                        <div className={styles.bottomButton_left}>
                            <Button style={{width: '100%'}} size={'large'} type={'primary'} onClick={() => {
                                setAssignModalOpen(true);
                            }}>任务人员分配</Button>
                        </div>


                        <div className={styles.bottomButton_right}>
                            <Button danger style={{width: '100%'}} size={'large'} type={'primary'} onClick={() => {
                                setRetreatModalOpen(true);
                            }}>退回至支队</Button>
                        </div>

                    </div>

                )}


                {appointmentRecord.appointmentState === '重派' && isSuperManager && (
                    <div>
                        <div className={styles.bottomButton_left}>
                            <Button style={{width: '100%'}} size={'large'} type={'primary'} onClick={() => {
                                setAppointmentRecord(appointmentRecord);
                                setReAssignDeptModalOpen(true);
                            }}>大队转派</Button>
                        </div>


                        <div className={styles.bottomButton_right}>
                            <Button danger style={{width: '100%'}} size={'large'} type={'primary'} onClick={() => {
                                setAssignModalOpen(true);
                            }}>作废</Button>
                        </div>

                    </div>
                )}

                <RepickAppointmentUser open={repickAppointmentUserModalOpen}
                                       onCancel={handleCloseRepickAppointmentUserModal}
                                       handleGetAppointmentRecord={async () => {
                                           handleGetAppointmentRecord(appointmentId);
                                       }}
                                       initUser={repickInitUser} type={repickUserType}
                                       confirmDate={appointmentRecord.confirmDate}
                                       confirmDateExtend={appointmentRecord.confirmDateExtend}
                                       appointmentId={appointmentRecord.appointmentId}/>

                <AssignAppointmentMemberModal open={assignModalOpen} onCancel={handleAssignModalCancel}
                                              appointmentId={appointmentId}/>
                <SignInInfoModal open={signInInfoModalOpen} onCancel={handleSignInLocationCancel}
                                 location={signInLocation}/>

                <ReAssignDeptModal open={reAssignDeptModalOpen} onCancel={handleCloseReAssignDeptModal}
                                   appointmentRecord={appointmentRecord}
                                   handleGetAppointmentRecord={async () => {
                                       handleGetAppointmentRecord(appointmentId)
                                   }}
                />

                <AppointmentRetreatModal open={retreatModalOpen} onCancel={handleCloseRetreatModal}
                                         appointmentRecord={appointmentRecord}
                                         handleGetAppointmentRecord={async () => {
                                             handleGetAppointmentRecord(appointmentId)
                                         }}/>


            </Spin>
        </Drawer>
    );
}

export default AppointmentDetailDrawer;