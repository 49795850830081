import React, {useEffect, useState} from 'react';
import styles from "./index.module.css";
import {
    Button,
    Drawer,
    Image,
    Input,
    message,
    Popconfirm,
    Select,
    Space,
    Table,
    Tooltip,
    TreeSelect,
    Upload
} from "antd";
import {showFail, showNetError, showSuccess} from "../../../components/functions";
import AddClassModal from "../../../components/addClassModal";
import AddExamDrawer from "../../../components/classExamDrawer";
import {
    EyeOutlined,
} from '@ant-design/icons';
import {deleteClass, getClasses, getQuestion, updateClass} from "../../../api";
import UpdateClassModal from "../../../components/updateClassModal";
import axios from "axios";
import {useNavigate} from "react-router-dom";

const ClassManage = () => {
    const [classList, setClassList] = useState<any>([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    const [searchCondition, setSearchCondition] = useState<any>({});
    const [newSearchCondition, setNewSearchCondition] = useState<any>(searchCondition);
    const [className, setClassName] = useState<string>();

    const handleNameChange = (value: any) => {
        setClassName(value.target.value)
    };

    const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
    const handleCloseAddModal = () => {
        setAddModalVisible(false);
    }

    const [updateModalVisible, setUpdateModalVisible] = useState<boolean>(false);
    const handleCloseUpdateModal = () => {
        setUpdateModalVisible(false);
    }

    const [examManageModalVisible, setExamManageModalVisible] = useState<boolean>(false);
    const handleCloseExamManageModal = () => {
        setExamManageModalVisible(false);
    }

    const [videoManageModalVisible, setVideoManageModalVisible] = useState<boolean>(false);
    const handleCloseVideoManageModal = () => {
        setVideoManageModalVisible(false);
    }

    useEffect(() => {
        handleGetClass(pageNum, pageSize, newSearchCondition);

    }, [pageNum, pageSize])

    const handleGetClass = (pageNum: number, pageSize: number, searchCondition: any) => {


        let params = {
            pageNum: pageNum,
            pageSize: pageSize,
            blockName:className?className:''
        }
        setLoading(true);
        getClasses(params).then((res: any) => {
            if (res?.code === 200) {
                setLoading(false)
                setTotal(Number(res?.data?.total));
                setClassList(res?.data?.records);
                console.log(res?.data)
            }else{
                showFail(res?.msg)
            }
        }).catch((err:any)=>{
            showNetError();
        })
    }
    const [classNow,setClassNow]=useState<any>()
    const navigate = useNavigate();

    const columns = [
        {
            title: '考试名称',
            dataIndex: 'blockName',
        },
        // {
        //     title: '考试简介',
        //     dataIndex: 'blockDetail',
        //     ellipsis: true,
        //     render: (text:any) => text && text?.length > 30
        //         ? <Tooltip placement='top' title={text}>
        //             <div style={{whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{text}</div>
        //         </Tooltip>
        //         :<div>{text}</div>,
        // },
        {
            title: '总分',
            dataIndex: 'totalScore',
        },
        {
            title: '及格分',
            dataIndex: 'passScore',
        },
        {
            title: '抽检题目数量',
            dataIndex: 'examNum',
        },
        // {
        //     title: '首页照片',
        //     dataIndex: 'blockPictureUrl',
        //     render: (text: any, record: any) => (
        //         <Image src={text} width={100} height={80}>管理</Image>
        //     )
        // },
        // {
        //     title: '考试视频',
        //     render: (text: any, record: any) => (
        //         <Button type={'primary'} onClick={() => {
        //             setClassNow(record);
        //             setVideoManageModalVisible(true)
        //         }}>管理</Button>
        //     )
        // },
        {
            title: '考试试题',
            render: (text: any, record: any) => (
                <Button type={'primary'} onClick={() => {
                    navigate("/manage/question", {replace: true});

                }}>管理</Button>
            )
        },
        {
            title: '操作',
            dataIndex: 'operate',
            render: (text: any, record: any) => (<>
                    <Button type={'link'} onClick={() => {
                        setClassNow(record);
                        setUpdateModalVisible(true);
                    }}>修改</Button>
                    <Popconfirm title={'是否确认删除该考试？'} onConfirm={() => {
                        deleteClass({blockId:record.blockId}).then((res: any) => {
                            setLoading(false);
                            console.log(res)
                            if (res?.code === 200) {
                                handleGetClass(pageNum, pageSize, newSearchCondition);
                            } else {
                                showFail(res?.msg)
                            }
                        }).catch((err: any) => {
                            console.log(err)
                        })
                    }}>
                        <Button type={'link'} danger>删除</Button>
                    </Popconfirm>
                </>
            ),
        },
    ]


    const [loadingExam, setLoadingExam] = useState<boolean>(false);


    const [pageNumExam, setPageNumExam] = useState(1);
    const [pageSizeExam, setPageSizeExam] = useState(10);
    const [totalExam, setTotalExam] = useState<number>(0);







    return (
        <div>
            <div className={styles.mainHead}>
                {/*<div className={styles.searchContent}>*/}

                {/*    <Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}}*/}
                {/*           onChange={handleNameChange} placeholder={'考试名称'} allowClear/>*/}

                {/*    /!*<Input onChange={handleNameChange} style={{width: 180, marginRight: '30px', marginTop: '10px'}}*!/*/}
                {/*    /!*       placeholder="手机号" allowClear/>*!/*/}
                {/*</div>*/}

                    {/*<Button style={{marginRight: '20px', marginTop: '10px'}} type='primary' onClick={() => {*/}
                    {/*     handleGetClass(1, 10, searchCondition);*/}
                    {/*    setPageNum(1);*/}
                    {/*    setPageSize(10);*/}
                    {/*    setNewSearchCondition(searchCondition)*/}
                    {/*}}>查询*/}
                    {/*</Button>*/}

                {classList?.length==0?  <Button type='primary' style={{ marginTop: '10px'}} onClick={() => {
                        setAddModalVisible(true);
                    }}>新增
                    </Button>:''}

            </div>

            <Table rowKey={'userId'} columns={columns} dataSource={classList}
                   loading={loading}
                   pagination={{
                       current: pageNum,
                       // onChange: onPageChange,
                       // onShowSizeChange: onPageSizeChange,
                       total: total,
                       // showSizeChanger: true,
                       defaultPageSize: pageSize,
                       // pageSizeOptions: ['10', '20', '50', '100'],
                   }}/>

            <AddClassModal
                         classNow={classNow}
                            open={addModalVisible}
                           onClose={handleCloseAddModal}
                           handleGetClass={async () => {
                               handleGetClass(pageNum, pageSize, newSearchCondition)
                           }}/>

            <UpdateClassModal
                           classNow={classNow}
                           open={updateModalVisible}
                           onClose={handleCloseUpdateModal}
                           handleGetClass={async () => {
                               handleGetClass(pageNum, pageSize, newSearchCondition)
                           }}/>


            {/*<AddExamDrawer open={examManageModalVisible}*/}
            {/*               onClose={handleCloseExamManageModal}*/}
            {/*               classNow={classNow}*/}
            {/*/>*/}




        </div>
    );
};

export default ClassManage;