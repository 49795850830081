import React, {useEffect, useState} from 'react';
import styles from "./index.module.css";
import {
    Button,
    Drawer,
    Image,
    Input,
    message,
    Popconfirm,
    Select,
    Space,
    Table,
    Tooltip,
    TreeSelect,
    Upload
} from "antd";
import {showFail, showNetError, showSuccess} from "../../../components/functions";
import AddClassModal from "../../../components/addClassModal";
import AddExamDrawer from "../../../components/classExamDrawer";
import {
    EyeOutlined,
} from '@ant-design/icons';
import {deleteClass, getClasses, getQuestion, updateClass} from "../../../api";
import UpdateClassModal from "../../../components/updateClassModal";
import axios from "axios";
import {useNavigate} from "react-router-dom";

const ClassManage = () => {
    const [classList, setClassList] = useState<any>([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    const [searchCondition, setSearchCondition] = useState<any>({});
    const [newSearchCondition, setNewSearchCondition] = useState<any>(searchCondition);
    const [className, setClassName] = useState<string>();


    useEffect(() => {
        handleGetClass(pageNum, pageSize, newSearchCondition);

    }, [pageNum, pageSize])


    const handleGetClass = (pageNum: number, pageSize: number, searchCondition: any) => {


        let params = {
            pageNum: pageNum,
            pageSize: pageSize,
            blockName:className?className:''
        }
        setLoading(true);
        getClasses(params).then((res: any) => {
            if (res?.code === 200) {
                setLoading(false)
                setTotal(Number(res?.data?.total));
                setClassList(res?.data?.records);
                console.log(res?.data)
                if(res.data.records.length>0){
                    setHasClass(true)
                    handleGetExam(pageNumExam, pageSizeExam,null,res?.data.records[0].blockId);
                    setClassNow(res?.data.records[0])
                }
            }else{
                showFail(res?.msg)
            }
        }).catch((err:any)=>{
            showNetError();
        })
    }
    const [classNow,setClassNow]=useState<any>()

    const [hasClass,setHasClass]=useState<boolean>(false)

    const [loadingExam, setLoadingExam] = useState<boolean>(false);


    const [pageNumExam, setPageNumExam] = useState(1);
    const [pageSizeExam, setPageSizeExam] = useState(10);
    const [totalExam, setTotalExam] = useState<number>(0);



    const handleGetExam = (pageNumExam: number, pageSizeExam: number, searchCondition: any,blockId:any) => {

        let params = {
            pageNum: pageNumExam,
            pageSize: pageSizeExam,
            blockId: blockId,
        }
        setLoadingExam(true);
        getQuestion(params).then((res: any) => {
            if (res?.code === 200) {
                setLoadingExam(false)
                setTotalExam(Number(res?.data?.total));
                setQuestions(res?.data?.records);
            }else{
                showFail(res?.msg)
            }
        }).catch((err)=>{
            showNetError();
        })
    }
    const onPageChangeExam = (_page: number) => {
        setPageNumExam(_page);
        handleGetExam( _page, pageSizeExam,null,classNow.blockId);
    };

    const onPageSizeChangeExam = (_pageSize: number) => {
        setPageSizeExam(_pageSize)
        handleGetExam( pageNumExam, _pageSize,null,classNow.blockId);
    };
    const [questions,setQuestions]=useState<any[]>()
    const columnsExam = [
        {
            title: '排序',
            dataIndex: 'questionNo',
            width:80,
        },
        {
            title: '题目',
            dataIndex: 'questionDetail',
            ellipsis: true,
            render: (text:any) => text && text?.length > 30
                ? <Tooltip placement='top' title={text}>
                    <div style={{whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{text}</div>
                </Tooltip>
                :<div>{text}</div>,
            width:500,
        },
        {
            title: '正确答案',
            dataIndex: 'correctAnswer',
            width:100,
        },
        {
            title: '选项',
            dataIndex: 'url',
            render: (text: any,record:any) => (
                <div>
                    {record.questionOptionList.map((res:any)=> {
                        return (
                            <div>{res.optionDetail} </div>
                        )
                    })}
                </div>
            )
        },
        {
            title: '题目类型',
            dataIndex: '分类',
            width:80,
        },
        {
            title: '分析',
            dataIndex: 'analysis',
            width:200,
        },
        {
            title: '题目类型',
            dataIndex: 'oneOrMore',
            width:80,
        }
    ]
    const createGroupM = (file: any) => {
        console.log(file.name.split('.'))
        let file_type = file.name.split('.')[1];
        if (file_type === 'xlsx') {
            let fm = new FormData();
            // @ts-ignore
            fm.append('file', file as RcFile)
            fm.append('blockId', classNow?.blockId)
            axios({
                url: 'http://localhost:8006/api/question/upload',
                method: 'post',
                data: fm,
                headers: {
                    'Authorization': `${window.localStorage.getItem('token')}`,
                    'content-type': 'multipart/form-data',
                },
            }).then((res: any) => {
                    if(res.status === 200) {
                        handleGetExam(pageNumExam, pageSizeExam, null,classNow.blockId)
                        message.success("上传成功")
                    }else {
                        message.error(res.msg)
                    }
                }
            ).catch((err) => {
                console.log(err);
            });

        } else {
            message.warning('仅支持 xlsx 格式')
        }
        return false
    }
    const removeGroupM = () => {

    }

    const navigate = useNavigate();


    return (
        <div>
            {hasClass?
        <div>
            <Space style={{marginBottom: 8}}>
                <Upload style={{float:"right"}} listType="picture-card" beforeUpload={createGroupM} maxCount={1}
                        onRemove={removeGroupM}
                        showUploadList={false}
                >
                    <div >
                        <div style={{marginBottom: 8}}>上传试题</div>
                    </div>
                </Upload>
            </Space>

            <Table rowKey={'userId'} columns={columnsExam} dataSource={questions}
                   loading={loadingExam}
                   pagination={{
                       current: pageNumExam,
                       onChange: onPageChangeExam,
                       onShowSizeChange: onPageSizeChangeExam,
                       total: totalExam,
                       showSizeChanger: true,
                       defaultPageSize: pageSizeExam,
                       pageSizeOptions: ['10', '20', '50', '100'],
                   }}/>

</div>:<Button onClick={()=>{
                    navigate("/manage/class", {replace: true});
                }} danger={true}>
                    请先设置考试参数
                </Button>}

        </div>
    );
};

export default ClassManage;