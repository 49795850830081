import {useEffect, useState} from "react";
import {Button, Input, Modal, Table} from "antd";
import {message} from "antd/es";
import s from "./index.module.css"
import {assignTaskMember, getUserByRole, judgeIdle} from "../../api";
import {formatDate, showFail, showSuccess, showWaringOfCustomDuration} from "../functions";

const RepickAppointmentUser = ({
                                   open,
                                   onCancel,
                                   initUser,
                                   type,
                                   confirmDate,
                                   confirmDateExtend,
                                   appointmentId,
                                   handleGetAppointmentRecord
                               }: {
    open: boolean,
    onCancel: () => void,
    initUser: any,
    type: number,
    confirmDate: any,
    confirmDateExtend: any,
    appointmentId: any,
    handleGetAppointmentRecord: any
}) => {
    const [userLoading, setUserLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    const [searchCondition, setSearchCondition] = useState<any>({
        username: "",
        mobile: "",
        userId: ""
    })
    const params: any = {}

    const handleUsernameChange = (e: any) => {
        setSearchCondition({
            ...searchCondition,
            username: e?.target?.value
        })
    }

    const handleMobileChange = (e: any) => {
        setSearchCondition({
            ...searchCondition,
            mobile: e?.target?.value
        })
    }


    const handleSearchUser = (searchCondition: any) => {
        if (searchCondition.username) {
            params.username = searchCondition?.username;
        }
        if (searchCondition.mobile) {
            params.mobile = searchCondition?.mobile;
        }
        if (searchCondition.roleType) {
            params.roleType = searchCondition?.roleType;
        }
        setUserLoading(true);
        getUserByRole(params).then((res: any) => {
            setUserLoading(false)
            if (res?.code === 200) {
                setUsers(res?.data)
            } else {
                showFail(res?.msg)
            }
        }).catch((err: any) => {
            console.log(err)
        })
    }

    const isMemberCanBeChoose = (data: any) => {
        return new Promise<any>((resolve, reject) => {
            judgeIdle(data).then((res: any) => {
                if (res?.msg === '可选') {
                    resolve(1);
                } else {
                    reject(res?.msg);
                }
            })
        })
    }


    const [thisTypeSelected, setThisTypeSelected] = useState<any>();

    const onSelectChange = (newSelectedRowKeys: any, selectedRows: any) => {
        let newId = newSelectedRowKeys[newSelectedRowKeys.length - 1];
        if (newId === initUser?.[0]?.userId) {
            showWaringOfCustomDuration("不能选择同一个人员!", 3);
            setSelectedRowKeys([]);
            setSelectedRows([])
        } else {
            let judgeData = {
                appointmentId: appointmentId,
                userId: newId,
                confirmDate: confirmDate,
                confirmDateExtend: confirmDateExtend
            }
            isMemberCanBeChoose(judgeData).then((res: any) => {
                if (thisTypeSelected) {
                    let thisTypeUserId = thisTypeSelected.userId;
                    let thisTypeKeyIndex = newSelectedRowKeys.findIndex((s: any) => s === thisTypeUserId);
                    newSelectedRowKeys.splice(thisTypeKeyIndex, 1);
                    let thisTypRowIndex = selectedRows.findIndex((s: any) => s.userId === thisTypeUserId);
                    selectedRows.splice(thisTypRowIndex, 1);
                }
                setSelectedRowKeys(newSelectedRowKeys);
                setSelectedRows(selectedRows)
            }).catch((err: any) => {
                console.log(err);
                showWaringOfCustomDuration(err, 3);
                setSelectedRowKeys([]);
                setSelectedRows([])
            });
        }
    };

    const onSelect = (record: any, selected: any) => {
        let newId = record?.userId;
        if (newId === initUser?.[0]?.userId) {
            setThisTypeSelected(null);
        } else {
            let judgeData = {
                appointmentId: appointmentId,
                userId: newId,
                confirmDate: confirmDate,
                confirmDateExtend: confirmDateExtend
            }
            if (selected) {
                isMemberCanBeChoose(judgeData).then((res: any) => {
                    setThisTypeSelected(record);
                }).catch((err: any) => {
                    console.log(err);
                    setThisTypeSelected(null);
                    // showWaringOfCustomDuration(err, 3)
                });
            } else {
                setThisTypeSelected(null);
            }
        }
    }

    const rowSelection: any = {
        selectedRowKeys,
        onChange: onSelectChange,
        preserveSelectedRowKeys: true,
        type: 'checkbox',
        onSelect,
        getCheckboxProps: (record: any) => {
            let disabled = false;
            let _init = initUser?.filter((a: any) => a.taskUserType !== type)?.map((s: any) => (s?.userId));
            if (_init.includes(record?.userId)) {
                disabled = true
            }
            return {
                value: record.userId,
                disabled
            }
        }
    }

    useEffect(() => {
        if (open) {
            let _searchCondition = {
                ...searchCondition,
                roleType: type
            };
            setSearchCondition(_searchCondition);
            handleSearchUser(_searchCondition);
            setSelectedRowKeys(initUser?.map((u: any) => u.userId));
            setSelectedRows(initUser);
            let thisTypeInitUser = initUser?.find((u: any) => u.taskUserType === type);
            if (thisTypeInitUser) {
                setThisTypeSelected(thisTypeInitUser);
            } else {
                setThisTypeSelected(null);
            }
        }
    }, [open])

    const hasSelected = selectedRowKeys.length > 0;
    const columns = [
        {
            title: '姓名',
            dataIndex: 'username',
            key: 'username'
        },
        {
            title: '手机号',
            dataIndex: 'mobile',
            key: 'mobile',
        },
        {
            title: '部门',
            dataIndex: 'dept',
            key: 'dept',
            render: (text: string, record: any) => (<div>
                {record?.sysUser?.dept}
            </div>)
        },
    ]

    return (
        <Modal width="40vw" maskClosable={false} title={`选择${type === 1 ? '监督员' : type === 2 ? '宣传员' : '安全检查人员'}`}
               open={open} onOk={() => {
            if (thisTypeSelected) {
                let userId = thisTypeSelected.userId;
                if (userId === initUser?.[0]?.userId) {
                    showWaringOfCustomDuration("不能选择同一个人员!", 3)
                } else {
                    let data: any;
                    switch (type) {
                        case 1:
                            data = {
                                appointmentId,
                                supervisionId: userId
                            };
                            break;
                        case 2:
                            data = {
                                appointmentId,
                                publicizeId: userId
                            };
                            break;
                        case 3:
                            data = {
                                appointmentId,
                                safeId: userId
                            };
                            break;
                    }
                    assignTaskMember(data).then((res: any) => {
                        if (res?.code === 200) {
                            handleGetAppointmentRecord().then(() => {
                                onCancel();
                                showSuccess("转派成功")
                            });
                        } else {
                            showFail(res?.msg)
                        }
                    })

                    // console.log('data', data)
                }


            } else {
                showWaringOfCustomDuration("请选择人员!", 3)
            }
            setSearchCondition({
                ...searchCondition,
                username: "",
                mobile: ""
            })
        }} onCancel={() => {
            setUserLoading(false);
            onCancel();
        }} okText="确认转派" cancelText="取消" okButtonProps={{disabled: !thisTypeSelected}}>
            <div className={s.userModalSearchBar}>
                <Input style={{width: "250px", marginRight: "30px"}} placeholder="用户名" allowClear
                       onChange={handleUsernameChange} value={searchCondition.username}/>
                <Input style={{width: "250px", marginRight: "30px"}} placeholder="手机号" allowClear
                       onChange={handleMobileChange} value={searchCondition.mobile}/>
                <Button type="primary" onClick={() => {
                    handleSearchUser(searchCondition);
                }}>查询</Button>
            </div>

            <div style={{height: "2vh"}}/>
            {thisTypeSelected && (<div style={{display: 'flex', flexDirection: 'row', fontSize: '15px'}}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row'
                    }}>已选中 {thisTypeSelected?.username}
                </div>
                <div style={{display: 'flex', alignItems: 'center', flexDirection: 'row'}}>
                    <Button style={{fontSize: '15px'}} type='link' onClick={() => {
                        setSelectedRows([]);
                        setSelectedRowKeys([])
                    }}>
                        取消选中
                    </Button>
                </div>
            </div>)}
            <Table rowSelection={rowSelection} columns={columns} rowKey={'userId'}
                   dataSource={users} loading={userLoading} pagination={{pageSize: 5}}/>
        </Modal>
    );
};

export default RepickAppointmentUser;

