import React, {useEffect, useState} from 'react';
import {CommunityPoint, iCommunity} from "../../interface";
import {deleteCommunityPoint, getCommunity, getDeptTree, reDispatchDept} from "../../api";
import {showFail, showNeedCompleteInfo, showNetError, showSuccess, showWaringOfCustomDuration} from "../functions";
import {Button, DatePicker, Form, Modal, Popconfirm, Select, Table} from 'antd';
//@ts-ignore
import {InfoWindow, Map, Marker, Markers} from "react-amap";
import AddNewPoiModal from "../addNewPoiModal";
import EditPoiModal from "../editPoiModal";

const ReAssignDeptModal = ({
                               open,
                               onCancel,
                               appointmentRecord,
                               handleGetAppointmentRecord
                           }: {
    open: boolean,
    onCancel: () => void,
    appointmentRecord: any,
    handleGetAppointmentRecord: any
}) => {

    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
        handleGetReceiveDepts();
    }, [open]);

    const [receiveDeptList, setReceiveDeptList] = useState<any[]>([]);
    const handleGetReceiveDepts = () => {
        getDeptTree().then((res: any) => {
            let _data = res?.data?.children?.map((d: any) => {
                return {
                    value: d?.deptId,
                    label: d?.name
                }
            });
            setReceiveDeptList(_data);
        })
    }

    const handleReAssignDept = () => {
        form.validateFields().then((value: any) => {
            let newDeptId = value?.deptId;

            if (newDeptId === appointmentRecord?.sysDept?.deptId) {
                showWaringOfCustomDuration("不能选择和原大队", 3)
            } else {
                let data = {
                    deptId: newDeptId,
                    appointmentId: appointmentRecord?.appointmentId,
                };

                reDispatchDept(data).then((res: any) => {
                    if (res?.code === 200) {
                        showSuccess("转派成功");
                        handleGetAppointmentRecord().then(()=>{
                            onCancel();
                        })
                    }
                }).catch((err: any) => {
                    console.log(err);
                    showFail(err);
                })
            }

        }).catch((err: any) => {
            console.log(err);
            showNeedCompleteInfo();
        })
    }


    return (
        <Modal open={open} onCancel={onCancel} title={'大队转派'} width={'800px'}
               onOk={handleReAssignDept}
               okText={"确认转派"}
        >
            <Form form={form} name="form" labelAlign="right" labelCol={{
                xs: {span: 24},
                sm: {span: 6},
            }} wrapperCol={{
                xs: {span: 24},
                sm: {span: 14},
            }}>

                <Form.Item
                    name="oriDept"
                    label="原接受大队"
                >
                    <div>
                        {appointmentRecord?.sysDept?.name}
                    </div>
                </Form.Item>

                <Form.Item
                    name="reassignReason"
                    label="转派原因"
                >
                    <div>
                        {appointmentRecord?.redispatchReason}
                    </div>
                </Form.Item>

                <Form.Item
                    name="deptId"
                    label="新接收大队"
                    rules={[
                        {
                            required: true, message: "请选择新大队"
                        },
                    ]}
                >
                    <Select options={receiveDeptList}/>
                </Form.Item>
            </Form>

        </Modal>
    );
};

export default ReAssignDeptModal;