import {
    Button,
    Form,
    Input,
    Modal,
    Popconfirm,
    Table,
    Image, InputNumber, TreeSelect, Select
} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import styles from "./index.module.css"
import {iHydrant, iPicture} from "../../../interface";
import {
    deleteCompany, getAllCompany, getQrcode, geUserExamPage, updateCompany,
} from "../../../api";
import {
    showFail,
    showNeedCompleteInfo,
    showSuccess,
    showWarning,
} from "../../../components/functions";
// @ts-ignore
import {debounce} from "lodash";
import {TableRowSelection} from "antd/es/table/interface";
import AddCompanyModal from "../../../components/addCompanyModal";
// @ts-ignore

const Company = () => {
        const [pageNum, setPageNum] = useState<number>(1);
        const [pageSize, setPageSize] = useState<number>(10);
        const [total, setTotal] = useState<number>(0);
        const [loading, setLoading] = useState<boolean>(false)
        const [userExamList, setUserExamList] = useState<iHydrant[]>([])
        const isCompany = JSON.parse(window.localStorage?.getItem("isCompany") as string);
        // const isCompany = false
        const isUnitManager = JSON.parse(window.localStorage?.getItem("isUnitManager") as string);
        // const isManager = JSON.parse(window.localStorage?.getItem("isManager") as string);
        const isManager = true

        const isSuperManager = JSON.parse(window.localStorage?.getItem("isSuperManager") as string);

        const onPageChange = (_page: number) => {
            setPageNum(_page);
        };

        const onPageSizeChange = (_page: number, _pageSize: number) => {
            setPageSize(_pageSize);
        };

        let params: any = {}
        const handleGetUserExamList = async (pageNum: number, pageSize: number, searchCondition?: any) => {
            setLoading(true);
            if (searchCondition?.companyName) {
                params.companyName = searchCondition?.companyName
            }
            if (searchCondition?.username) {
                params.username = searchCondition?.username
            }
            if (searchCondition?.max) {
                params.max = searchCondition?.max
            }
            if (searchCondition?.isPass) {
                params.isPass = searchCondition?.isPass
            }
            if (searchCondition?.min) {
                params.min = searchCondition?.min
            }
            if (searchCondition?.areaId && searchCondition?.areaId != 1) {
                params.areaId = searchCondition?.areaId
            }
            params.pageSize = pageSize;
            params.pageNum = pageNum;
            if (isCompany) {
                params.companyId = window.localStorage?.getItem("companyId");
            }
            if (isUnitManager) {
                params.unitId = window.localStorage?.getItem("unitId");
            }
            if (isManager) {
                let areaId = window.localStorage?.getItem("areaId");
                if (areaId != null && areaId !== '1') {
                    params.areaId = window.localStorage?.getItem("areaId");
                }
            }
            geUserExamPage(params).then((res: any) => {
                setLoading(false);
                if (res?.code === 200) {
                    let list = res?.data?.records;
                    setTotal(Number(res?.data?.total));
                    for (let i = 0; i < list.length; i++) {
                        list[i].key = res.data?.records[i]?.userExamId;
                    }
                    setUserExamList(list);
                } else {
                    showFail(res?.msg)
                }
            }).catch((err:any) => {
                console.log(err)
            })
        }

        useEffect(() => {
            handleGetUserExamList(pageNum, pageSize, newSearchCondition);

        }, [pageSize, pageNum])

        const [form] = Form.useForm();


        const [searchCondition, setSearchCondition] = useState({
            companyName: "",
            username:"",
            isPass:"",
            max:"",
            min:""

        });
        const [newSearchCondition, setNewSearchCondition] = useState(searchCondition);
        const [codeUrl, setCodeUrl] = useState();
        const columns: any = [
            // {
            //     title: '考试课程',
            //     dataIndex: ['block', 'blockName'],
            // },
            {
                title: '考生姓名',
                dataIndex: ['sysUser', 'username'],
            },
            {
                title: '考生单位',
                dataIndex: ['company','companyName'],
            },
            {
                title: '得分',
                dataIndex: 'scoreTotal',
            },
            {
                title: '合格分数',
                dataIndex: ['block', 'passScore'],
            },
            {
                title: '总分',
                dataIndex: ['block', 'totalScore'],
            },
            {
                title: '是否合格',
                render: (_: any, record: any) => (
                    <div>
                        {record?.scoreTotal >= record?.block?.passScore ? '合格' : '不合格'}
                    </div>
                )
            },
            // {
            //     title: '操作',
            //     dataIndex: 'operation',
            //     key: 'operation',
            //     render: (_: any, record: any) => (
            //         <div>
            //             {isManager ?
            //                 <div>
            //                     <Popconfirm title={"是否确认删除该单位"} onConfirm={() => {
            //                         deleteCompany({companyId: record?.companyId}).then((res: any) => {
            //                             if (res?.code === 200) {
            //                                 handleGetUserExamList(pageNum, pageSize, newSearchCondition).then(() => {
            //                                     showSuccess(res?.msg);
            //                                 })
            //                             } else {
            //                                 showFail(res?.msg)
            //                             }
            //                         }).catch((err:any) => {
            //                             console.log(err)
            //                         })
            //                     }}>
            //                         <Button danger type={'link'} color={'danger'}>删除</Button>
            //                     </Popconfirm>
            //
            //                     <Button type={'link'} color={'primary'}
            //                             onClick={() => {
            //                                 setUpdateModalVisible(true);
            //                                 form.setFieldsValue({'companyId': record.companyId})
            //                                 form.setFieldValue('companyName', record.companyName)
            //                                 form.setFieldValue('contactName', record.contactName)
            //                                 form.setFieldValue('contactMobile', record.contactMobile)
            //                                 form.setFieldValue('address', record.address)
            //                                 form.setFieldValue('remark', record.remark);
            //                             }}
            //                     >修改</Button>
            //                 </div> : ''}
            //         </div>
            //     )
            // },
        ];

        //新增
        const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
        const handleCloseAddModal = () => {
            setAddModalVisible(false);
        }
        const [updateModalVisible, setUpdateModalVisible] = useState<boolean>(false);
        const handleCloseUpdateModal = () => {
            setUpdateModalVisible(false);
        }


        const [imgModalVisible, setImgModalVisible] = useState<boolean>(false);
        const handleCloseImgModal = () => {
            setImgModalVisible(false);
        }

        const handleUpdateNewCompany = () => {
            form.validateFields().then((value) => {
                let newCompany = {
                    companyName: value?.companyName,
                    companyId: form.getFieldValue('companyId'),
                    contactName: value?.contactName,
                    contactMobile: value?.contactMobile,
                    address: value?.address,
                    remark: value.remark
                }
                setLoading(true);
                updateCompany(newCompany).then((res: any) => {
                    setLoading(false);
                    form.resetFields();
                    if (res?.code === 200) {
                        handleGetUserExamList(pageNum, pageSize).then(() => {
                            showSuccess(res?.msg);
                            handleCloseUpdateModal();
                        })
                    } else {
                        showFail(res?.msg)
                    }
                }).catch((err: any) => {
                    console.log(err)
                })

            }).catch((err) => {
                console.log(err);
                showNeedCompleteInfo();
            })
        }


        const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

        function downloadQrcode(parkCompanies: any) {
            let url = "";
            let token = window.localStorage.getItem('token');
            if (process.env.NODE_ENV === 'development') {
                url = "http://localhost:8002/api";
            } else {
                url = "https://www.hzgxtc.cn/api";
            }
            return fetch(url + '/userExam/downloadQrcode', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${token}`, // 确保 Bearer token 格式正确
                },
                body: JSON.stringify(parkCompanies)
            }).then(response => {
                if (response.ok) {
                    return response.blob(); // 转换响应为 Blob 对象
                }
                throw new Error('Network response was not ok.');
            }).then(blob => {
                // 创建下载链接并触发下载
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'images.zip'; // 设置下载文件名
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url); // 释放 Blob URL
            })
                .catch(error => {
                    console.error('There was a problem with the fetch operation:', error);
                });
            ;
        }

        const start = () => {
            setLoading(true);
            // ajax request after empty completing
            let parkCompanies = []
            for (let i = 0; i < selectedRowKeys.length; i++) {
                parkCompanies.push({companyId: selectedRowKeys[i]})
            }
            downloadQrcode(parkCompanies)


            setTimeout(() => {
                setSelectedRowKeys([]);
                setLoading(false);
            }, 1000);
        };

        const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
            console.log('selectedRowKeys changed: ', newSelectedRowKeys);
            setSelectedRowKeys(newSelectedRowKeys);
        };

        const rowSelection: TableRowSelection<any> = {
            selectedRowKeys,
            onChange: onSelectChange,
        };

        const hasSelected = selectedRowKeys.length > 0;

        return (
            <div className={styles.main}>

               <div className={styles.mainHead}>
                   {!isCompany ?   <Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}} onChange={(e: any) => {
                        setSearchCondition({...searchCondition, companyName: e?.target?.value})
                    }} placeholder={'单位名称'} allowClear></Input>: ''}
                   <Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}} onChange={(e: any) => {
                       setSearchCondition({...searchCondition, username: e?.target?.value})
                   }} placeholder={'考生姓名'} allowClear></Input>
                   <Select  placeholder={'是否合格'} style={{width: '180px', marginRight: '30px', marginTop: '10px'}} allowClear={true}
                            onChange={(e: any) => {
                                setSearchCondition({...searchCondition, isPass: e})
                            }}
                   >
                       <Select.Option value="1">合格</Select.Option>
                        <Select.Option value="0">不合格</Select.Option>
                   </Select>
                   <InputNumber style={{width: '180px', marginRight: '30px', marginTop: '10px'}} onChange={(e: any) => {
                       setSearchCondition({...searchCondition, min: e})
                   }} placeholder={'最低分'} ></InputNumber>
                   <InputNumber style={{width: '180px', marginRight: '30px', marginTop: '10px'}} onChange={(e: any) => {
                       setSearchCondition({...searchCondition, max: e})
                   }} placeholder={'最高分'} ></InputNumber>
                    <div className={styles.searchButton}>

                        <Button style={{marginRight: '20px', marginTop: '10px'}} type='primary' onClick={() => {
                            handleGetUserExamList(1, 10, searchCondition);
                            setPageNum(1);
                            setPageSize(10);
                            setNewSearchCondition(searchCondition)
                        }}>查询
                        </Button>
                        {/*{isManager ?*/}
                        {/*    <Button type='primary' style={{marginRight: '20px', marginTop: '10px'}} onClick={() => {*/}
                        {/*        setAddModalVisible(true);*/}
                        {/*    }}>新增*/}
                        {/*    </Button> : ''}*/}

                    </div>

                </div>
                {/*<Button type="primary" onClick={start} disabled={!hasSelected} loading={loading}*/}
                {/*        style={{marginBottom: '10px'}}>*/}
                {/*    导出二维码*/}
                {/*</Button>*/}
                <Table
                    // rowSelection={rowSelection}
                    columns={columns} dataSource={userExamList} loading={loading}
                    pagination={{
                        current: pageNum,
                        onChange: onPageChange,
                        onShowSizeChange: onPageSizeChange,
                        total: total,
                        showSizeChanger: true,
                        defaultPageSize: pageSize,
                        pageSizeOptions: ['10', '20', '50', '100'],
                    }}/>


                {/*<AddCompanyModal open={addModalVisible} onClose={handleCloseAddModal}*/}
                {/*                 handleGetCompany={async () => {*/}
                {/*                  await handleGetUserExamList(pageNum, pageSize, newSearchCondition)*/}
                {/*              }}/>*/}
                <Modal open={updateModalVisible} onCancel={() => {
                    handleCloseUpdateModal();
                    setLoading(false)
                }} title={'单位修改'} maskClosable={false} okText={'确认提交'}
                       onOk={debounce(handleUpdateNewCompany, 1000)} okButtonProps={{loading: loading}} destroyOnClose>
                    <Form form={form} name="form" labelAlign="right" labelCol={{
                        xs: {span: 24},
                        sm: {span: 6},
                    }} wrapperCol={{
                        xs: {span: 24},
                        sm: {span: 14},
                    }}>
                        <Form.Item
                            name="companyName"
                            label="公司名称"
                            rules={[
                                {
                                    required: true, message: "请输入公司名称"
                                },
                            ]}
                        >
                            <Input placeholder="请输入公司名称"/>
                        </Form.Item>
                        <Form.Item
                            name="address"
                            label="详细地址"
                            rules={[
                                {
                                    required: true, message: "请输入详细地址"
                                },
                            ]}
                        >
                            <Input placeholder="请输入详细地址" style={{width: '100%'}}/>
                        </Form.Item>


                        <Form.Item
                            name="contactName"
                            label="联系人姓名"
                            // rules={[
                            //     {
                            //         required: true, message: "请输入联系人姓名"
                            //     },
                            // ]}
                        >
                            <Input placeholder="请输入联系人姓名"/>
                        </Form.Item>
                        <Form.Item
                            name="contactMobile"
                            label="联系人电话"
                            // rules={[
                            //     {
                            //         required: true, message: "请输入联系人电话"
                            //     },
                            // ]}
                        >
                            <InputNumber placeholder="请输入联系人电话" style={{width: '100%'}}/>
                        </Form.Item>
                        <Form.Item
                            name="remark"
                            label="备注"
                        >
                            <Input placeholder="请输入备注" style={{width: '100%'}}/>
                        </Form.Item>
                    </Form>
                </Modal>

                <Modal open={imgModalVisible} onCancel={() => {
                    handleCloseImgModal();
                    setLoading(false)
                }} title={'二维码'} maskClosable={false} footer={<Button>关闭</Button>} destroyOnClose>

                    <div style={{textAlign: "center"}}><Image src={codeUrl}></Image></div>
                </Modal>
            </div>
        );
    }
;

export default Company;